<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack" :disabled="lockBack">前のｱﾝｹｰﾄへ</v-btn>
        </div>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">

      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4" justify="center">
        <v-btn color="green" small class="white--text" @click="changeFavoriteSurveys" v-if="this.favoriteFlg">お気に入り解除<v-icon color="orange">mdi-star</v-icon></v-btn>
        <v-btn color="green" small class="white--text" @click="changeFavoriteSurveys" v-if="!this.favoriteFlg">お気に入り追加<v-icon>mdi-star-outline</v-icon></v-btn>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">

      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward" :disabled="lockForward">次のｱﾝｹｰﾄへ</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
      <v-card-title class="d-flex justify-center pa-0 mt-6">{{ title }}</v-card-title>
      <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0 red--text" v-if="limitOverFlg">回答期限：{{ limitDate }}</v-card-text>
      <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0" v-if="!limitOverFlg">回答期限：{{ limitDate }}</v-card-text>
      <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0 red--text" v-if="allowUpdateFlg == false">※回答後の更新は受け付けていません</v-card-text>
      <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0">投稿者：{{ createUser }}</v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <p style="white-space:pre-wrap;" v-html="autoLink(body)"></p>
      </v-card-text>
      <!-- 匿名 -->
      <v-card-text v-if="anonymousFlg" class="d-flex justify-center flex-column">
        <div color="red">このアンケートは匿名での回答となります。学校が個人を特定することはありません。</div>
      </v-card-text>
      <v-card
        class="d-flex justify-center flex-column mx-auto my-0 flat"
        width="1200"
        color="#fff"
      >
        <v-list>
          <v-subheader>添付ファイル</v-subheader>
          <v-list-item-group  v-if="fileList.length" color="primary">
            <v-list-item v-for="item in fileList" @click="onClickDwonload(item)" :key="item.attachmentId"  >
              <a class="text-decoration-none">{{ item.fileName }}</a>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group  v-if="!fileList.length" color="primary">
            <v-list-item>ファイルはありません</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-card>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-left">回答欄</v-card-title>
        <div v-for="(question, index) in questionList" :key="question.id">
          <v-row dense class="mx-3 mt-1 lime lighten-4">
            <v-col cols="12" class="py-0">
              <div class="mx-2">{{ (index + 1) }}問目 <v-icon v-if="question.required">$cet_required</v-icon></div>
            </v-col>
          </v-row>
          <v-row dense class="mx-5 mt-1">
            <v-col cols="12" class="py-0">
              <v-card-text class="d-flex justify-center flex-column">
                <p style="white-space:pre-wrap;" v-html="autoLink(question.content)"></p>
              </v-card-text>
            </v-col>
          </v-row>
          <v-divider class="mx-4"></v-divider>
          <!-- テキスト回答のアンケート -->
          <v-row dense class="ml-5 my-1" v-if="question.type == '0'">
            <v-col cols="12">
              <ValidationProvider name="回答" :rules="question.rules" v-slot="{ errors }" :vid="question.id">
                <v-row dense class="mx-1 my-0">
                  <v-col cols="12" class="pb-0">
                    <v-textarea
                      v-model="question.value"
                      label="回答"
                      :error-messages="errors[0]"
                      :counter="question.charLimit"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- チェックボックス回答のアンケート -->
          <v-row dense class="ml-5 my-1" v-if="question.type=='1'">
            <v-col cols="12">
              <ValidationProvider name="チェック" :rules="question.rules" v-slot="{ errors }" :vid="question.id">
                <v-row dense v-for="checkOption in question.option" :key="checkOption.id" class="mx-1 my-0">
                  <v-col cols="12" class="py-0">
                    <v-checkbox v-model="question.value"
                    :label="checkOption.title"
                    :value="checkOption.id"
                    :error-messages="errors[0]"
                    dense
                    hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </v-col>
          </v-row >

          <!-- ラジオボタン回答のアンケート -->
          <v-row dense class="ml-5 my-1" v-if="question.type=='2'">
            <v-col cols="12">
              <ValidationProvider name="ラジオ" :rules="question.rules" v-slot="{ errors }" :vid="question.id" :skipIfEmpty="false">
                <v-row dense class="mx-1 my-0">
                  <v-col cols="12" class="py-0">
                    <v-radio-group 
                      v-model="question.value"
                      :error-messages="errors[0]"
                      hide-details="auto"
                      class="my-0"
                      >
                      <v-radio v-for="radioOption in question.option" :key="radioOption.id"
                        :label="radioOption.title"
                        :value="radioOption.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>

        <v-card-actions class="mt-3">
          <v-btn color="grey" class="mr-auto mt-0 black--text" @click="cancelSurveyDetail" width="30%">戻る</v-btn>
          <v-btn color="red" class="mr-auto mt-0 black--text" @click="clearSurveyInput" :disabled="!inputFlg || inputLockFlg" width="30%">回答をクリア</v-btn>
          <v-btn color="accent" class="ml-auto mt-0 black--text" @click="saveSurveyDetails" :disabled="invalid || inputLockFlg" width="30%">回答</v-btn>
        </v-card-actions>  

      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { saveAsCommon } from '../../common';
import { httpsCallable, getFunctions } from "firebase/functions";
import Autolinker from 'autolinker';
export default {
  props: ['responseId','surveyListBuff','surveyListIndex'],
  data() {
    return {
      userTop : "",
      title : "",
      body : "",
      surveyId : "",
      groupId : "",
      limitDate : "",
      limitDateTime : null,
      limitBlockFlg : false,
      limitOverFlg : false,
      allowUpdateFlg : false,
      inputLockFlg : false,
      createUser : "",
      fileList: [],
      questionList : [],
      inputFlg: false,
      lockBack: false,
      lockForward: false,
      anonymousFlg: false,
      favoriteFlg: false,
    };
  },
  watch: {
    responseId: async function () {
      await this.load();
    }
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.lockBack = (this.surveyListIndex == 0)?true:false;
      this.lockForward = (this.surveyListBuff.length == (this.surveyListIndex+1))?true:false;
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "loadSurveyDetails",
        responseId: this.responseId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.userTop = doc.data.userTop;
        this.title = doc.data.title;
        this.body = doc.data.body;
        this.surveyId = doc.data.surveyId;
        this.groupId = doc.data.groupId;
        this.limitDate = doc.data.limitDate;
        this.limitDateTime = doc.data.limitDateTime;
        this.limitBlockFlg = doc.data.limitBlockFlg;
        this.allowUpdateFlg = doc.data.allowUpdateFlg;
        this.createUser = doc.data.createUser;
        this.fileList = doc.data.fileList;
        this.questionList = doc.data.questionList;
        this.anonymousFlg = doc.data.anonymousFlg;
        this.inputFlg = doc.data.inputFlg;
        this.favoriteFlg = doc.data.favoriteFlg;
        // ローディング非表示
        this.$root.loading = false;
        var now = new Date();
        // 締切超過制御
        if(doc.data.limitBlockFlg && this.limitDateTime < now.getTime()) {
          this.inputLockFlg = true;
        }
        if(doc.data.allowUpdateFlg == false && doc.data.inputFlg == true){
          this.inputLockFlg = true;
        }
        if(this.limitDateTime < now.getTime()) {
          this.limitOverFlg = true;
        }

      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
    },
    // 前の連絡へボタン
    toBack() {
      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : this.surveyListBuff[this.surveyListIndex - 1],
          surveyListBuff: this.surveyListBuff,
          surveyListIndex: this.surveyListIndex - 1,
        } 
      });
    },
    // 次の連絡へボタン
    toForward() {
      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : this.surveyListBuff[this.surveyListIndex + 1],
          surveyListBuff: this.surveyListBuff,
          surveyListIndex: this.surveyListIndex + 1,
        } 
      });
    },
    // 戻るボタン押下
    cancelSurveyDetail(){
      this.$router.push({
        name: this.userTop,
      });
    },
    // お気に入り切り替えボタン
    async changeFavoriteSurveys()  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteSurveys",
        surveyId: this.surveyId,
        favoriteFlg: this.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.favoriteFlg = !this.favoriteFlg;
    },
    // 回答ボタン押下
    async saveSurveyDetails(){

      var now = new Date();
      // 締切超過制御
      if(this.limitBlockFlg && this.limitDateTime < now.getTime()) {
        alert("回答締切日時を超過しました。");
        return;
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveSurveyDetails",
        responseId : this.responseId,
        groupId : this.groupId,
        questionList : this.questionList,
      }

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: this.userTop,
      });
    },
    // アンケート入力をクリア
    async clearSurveyInput() {
      
      var now = new Date();
      // 締切超過制御
      if(this.limitBlockFlg && this.limitDateTime < now.getTime()) {
        alert("回答締切日時を超過しました。");
        return;
      }

      var result = confirm("アンケートを未回答に戻します。よろしいですか？");
      if (!result) {
        return;
      }

      var param = {
        funcName:"clearSurveyInput",
        responseId : this.responseId,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      this.$router.push({
        name: this.userTop,
      });
    },
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  }
};
</script>
<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          連絡一覧
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 検索用のテキスト入力 -->
        <v-autocomplete
          v-model="groupFilterValue"
          label="グループ指定"
          placeholder="グループ指定"
          :items="groupNames"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 検索用のテキスト入力 -->
        <v-autocomplete
          v-model="postUsersFilterValue"
          label="作成者指定"
          placeholder="作成者指定"
          :items="postUsers"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 検索用の年度入力 -->
        <v-autocomplete
          v-model="selectNendo"
          label="年度指定"
          placeholder="年度指定"
          :items="nendoList"
          @change="reload"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-switch 
          v-model="viewFavoriteOnly"
          dense
          class="mt-1"
          label="お気に入りのみ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="contacts"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isContactsLoding" loading-text="読込中"
          >
          <!-- 投稿日と投稿者 -->
          <template #[`item.postUser`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.postDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
          </template>
          <!-- グループ名と代表者 -->
          <template #[`item.groupName`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.ownerUser }}</v-col></v-row>
          </template>
          <!-- 対象/コメントフラグ -->
          <template #[`item.target`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <v-icon x-large v-if="item.teacherFlg">$teacher</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip bottom v-if="item.commentFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on" >mdi-comment-text-outline</v-icon>
                  </template>
                  <span>コメント登録可</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- お気に入り -->
          <template #[`item.favoriteFlg`]="{ item }">
            <v-tooltip top v-if="item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteContacts(item)"><v-icon color="orange">mdi-star</v-icon></v-btn>
              </template>
              <span>お気に入りを解除する</span>
            </v-tooltip>
            <v-tooltip top v-if="!item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteContacts(item)"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <span>お気に入りに追加する</span>
            </v-tooltip>
          </template>
          <!-- 編集と通知 -->
          <template #[`item.editAndMail`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickEditItem(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>連絡を編集する</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center" style="white-space : nowrap;">
                <v-tooltip bottom v-if="tounendo == selectNendo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="openMailModal(item)">
                      <v-icon>mdi-email-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>通知予約を登録する</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickMailLog(item)">
                      <v-icon>mdi-email-search-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>通知状況を確認する</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 公開期間 -->
          <template #[`item.publishDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.openDate }}～</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.closeDate }}</v-col></v-row>
          </template>
          <!-- 公開状態 -->
          <template #[`item.isOpen`]="{ item }">
            <v-btn color="accent" class="black--text" v-if="item.publishFlg && tounendo == selectNendo && !item.beforeOpeningFlg" @click="onClickChangeOpenStatus(item)">公開中</v-btn>
            <v-btn color="white" class="black--text" v-if="item.publishFlg && tounendo == selectNendo && item.beforeOpeningFlg" @click="onClickChangeOpenStatus(item)">公開前</v-btn>
            <v-btn color="grey" class="black--text" v-if="!item.publishFlg && tounendo == selectNendo" @click="onClickChangeOpenStatus(item)">停止中</v-btn>
            <v-btn color="grey" class="black--text" v-if="tounendo != selectNendo" :disable="true">停止中</v-btn>
          </template>
          <!-- ユーザー閲覧数 -->
          <template #[`item.count`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="item.isCountLoading" v-bind="attrs" v-on="on" @click="onClickReceiptStatus(item)">{{ item.count }}</v-btn>
              </template>
              <span>閲覧状況を確認する</span>
            </v-tooltip>
          </template>
          <!-- 操作 -->
          
          <template #[`item.action`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickExcelExport(item)">
                      <v-icon color="green">mdi-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>閲覧状況をExcel出力する</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip bottom v-if="tounendo == selectNendo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>連絡を削除する</span>
                </v-tooltip>
                <v-tooltip bottom v-if="tounendo != selectNendo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" :disable="true">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>連絡を削除する</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- メール送信モーダル -->
    <v-dialog
      v-model="mailDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="ml-12">連絡を通知する</v-card-title>
        <v-list-item-subtitle class="ml-3" style="font-size:.835rem;">非公開の場合は公開になり次第通知されます</v-list-item-subtitle>
        <v-card-text>
          <v-radio-group v-model="mailType" :mansdatory="false">
            <v-radio label="連絡の登録を通知する" value="0"></v-radio>
            <v-radio label="連絡の修正を通知する" value="1"></v-radio>
            <v-radio label="未読者に対して再通知する" value="2"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="grey" @click="mailDialog = false">
                閉じる
              </v-btn>
            </v-col>
            <v-col cols="3" justify="center"></v-col>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="accent" @click="sendMail()">
                送信予約
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- エクセル出力モーダル -->
    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title style="font-size:1.23rem;">連絡の閲覧状況を出力する</v-card-title>
        <v-card-subtitle class="pl-5">※対象グループが全校ですと、最大で20秒程度お待ちいただく必要があります。</v-card-subtitle>
        <v-card-actions>
          <v-row dense>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="grey" :disabled="isExportingFlg" @click="exportDialog = false">
                閉じる
              </v-btn>
            </v-col>
            <v-col cols="3" justify="center"></v-col>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="accent" :disabled="isExportingFlg" :loading="isExportingFlg" @click="exportExcelContactStatus()">
                出力
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 通知履歴モーダル -->
    <v-dialog
      v-model="mailLogDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="pl-5" style="font-size:1.012rem;">通知履歴</v-card-title>
        <v-card-subtitle class="pl-5">※完了まで数分かかる場合があります</v-card-subtitle>
        <v-card-subtitle class="pl-5">※通知可能なメールもしくはスマホアプリを１件としてカウントしています</v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="mailLogHeader"
            :items="mailLogList"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
          >
          <template #[`item.cancel`]="{ item }">
            <v-btn color="green" small class="white--text" v-if="item.status == '0' || item.status == '1'" @click="cancelNotification(item)">キャンセル</v-btn>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../common';
export default {
  data() {
    return {
      postUsersFilterValue: "",
      groupFilterValue: "",
      tounendo: "",
      selectNendo: "",
      nendoList : [],
      viewFavoriteOnly: false,
      postUsers : [],
      groupNames : [],
      headers: [
        { text: "作成日時/作成者", value: "postUser", width: "15%", filter: this.postUserFilter },  
        { text: "タイトル", value: "title", width: "35%" },
        { text: "グループ名/代表者", value: "groupName", width: "35%", filter: this.groupNameFilter },
        { text: "対象/コメント", value: "target" },
        { text: "お気に入り", value: "favoriteFlg", width: "5%", filter: this.favoriteFilter },
        { text: "編集/通知", value: "editAndMail", width: "5%" },
        { text: "公開期間", value: "publishDate", width: "20%" },
        { text: "公開状態", value: "isOpen", width: "5%" },
        { text: "全体：既読：未読", value: "count", width: "5%" },
        { text: "操作", value: "action", width: "5%" },
      ],
      contacts: [],
      isContactsLoding: false,
      mailDialog: false,
      mailContactId: "",
      mailType: "1",
      exportDialog: false,
      exportContactId: "",
      mailLogDialog: false,
      mailLogHeader: [
        { text: "登録日時", value: "insDate"},
        { text: "通知区分", value: "typeNm"},
        { text: "通知設定者", value: "postUser"},
        { text: "状態", value: "statusNm"},
        { text: "全件数", value: "allCount"},
        { text: "通知済件数", value: "doneCount"},
        { text: "未通知件数", value: "notYetCount"},
        { text: "通知完了日時", value: "updDate"},
        { text: "キャンセル", value: "cancel"},
      ],
      mailLogList: [],
      perPage: 30,
      isExportingFlg: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.perPage = this.$store.state.parPageContactList;
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName: "commonGetNendo"}).then((ret) => {
      this.tounendo = ret.data.tounendo;
      this.selectNendo = ret.data.tounendo;
      this.nendoList = ret.data.list;
    });
    await this.reload();
  },
  watch: {
    perPage: function(newVal) {
      this.$store.dispatch('setParPageContactList', newVal);
    },
  },
  methods: {
    // 投稿者名フィルタ
    postUserFilter(value) {
      if (!this.postUsersFilterValue) {
        return true;
      }
      return value.toString().includes(this.postUsersFilterValue);
    },
    // グループ名フィルタ
    groupNameFilter(value) {
      if (!this.groupFilterValue) {
        return true;
      }
      return value.toString().includes(this.groupFilterValue);
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    async changeFavoriteContacts(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteContacts",
        contactId: item.contactId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      item.favoriteFlg = !item.favoriteFlg;
    },
    async reload() {
      var temp_postUsers = new Set();
      var temp_groupNames = new Set();
      this.contacts = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isContactsLoding = true;
      var param = {
        funcName:"loadContactList",
        nendo: this.selectNendo
      }
      await schoolAidHomeLogined(param).then((contacts) => {
        var ret = [];
        contacts.data.forEach((contact) => {
          temp_postUsers.add(contact.postUser);
          temp_groupNames.add(contact.groupName);
          var count = contact.allCount + "：" + contact.readCount + "：" + contact.unreadCount;
          var row = {
            postDate: contact.postDate,
            postUser: contact.postUser,
            groupName : contact.groupName,
            ownerUser : contact.ownerUser,
            studentFlg: contact.studentFlg,
            parentFlg: contact.parentFlg,
            teacherFlg: contact.teacherFlg,
            title : contact.title,
            openDate: contact.openDate,
            closeDate: contact.closeDate,
            publishFlg : contact.publishFlg,
            count : count,
            allCount : contact.allCount,
            contactId : contact.contactId,
            isCountLoading: contact.isCountLoading,
            commentFlg: contact.commentFlg,
            mailAddressAuthFlg: contact.mailAddressAuthFlg,
            favoriteFlg: contact.favoriteFlg,
            targetSelectFlg: contact.targetSelectFlg,
            beforeOpeningFlg: contact.beforeOpeningFlg,
          };

          ret.push(row);
        });
        this.contacts = ret;
        this.isContactsLoding = false;
        this.postUsers = Array.from(temp_postUsers);
        this.groupNames = Array.from(temp_groupNames);
      });
    },

    // 公開状態変更
    async onClickChangeOpenStatus(item) {
      var result = confirm("公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "changeOpenStatus",
        contactId: item.contactId,
        publishFlg: item.publishFlg,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },

    // 連絡修正
    onClickEditItem(item) {
      this.$router.push({
        name: "ContactEdit",
        params: {
          id: item.contactId,
        }
      });
    },
    // 未読一覧
    onClickReceiptStatus(item) {
      if(item.allCount > 200){
        var result = confirm("対象者が200名を超える場合はExcel出力を推奨しています。続行しますか？");
        if (!result) {
          return;
        }
      }

      this.$router.push({
        name: "ReceiptStatus",
        params: {
          id: item.contactId,
        }
      });
    },
    // 論理削除
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "deleteContact",
        contactId: item.contactId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },
    // 通知送信モーダルを開く
    openMailModal(item) {
      this.mailContactId = item.contactId;
      this.mailType = "1";
      this.mailDialog = true;
    },
    // 通知処理
    async sendMail() {
      var result = confirm("通知してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "sendMail",
        contactId: this.mailContactId,
        mailType: this.mailType,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then(() => {
        alert("受け付けました");
      });
      // ローディング終了
      this.$root.loading = false;
      this.mailDialog = false;
    },
    // 既読未読確認
    onClickExcelExport(item) {
      this.exportContactId = item.contactId;
      this.exportDialog = true;
    },
    // エクセル出力処理
    async exportExcelContactStatus() {
      var result = confirm("出力してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.isExportingFlg = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "exportExcelContactStatus",
        contactId: this.exportContactId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
          alert("ダウンロードを開始します。\nダウンロードフォルダをご確認ください。\n※ファイルを確認出来ない場合はポップアップがブロックされていないか\nご確認ください。");
        }
      });
      // ローディング終了
      this.isExportingFlg = false;
      this.exportDialog = false;
    },
    // 通知履歴確認
    async onClickMailLog(item) {
      var param = {
        funcName: "commonGetMailLog",
        id: item.contactId,
        type: "C",
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        this.mailLogList = ret.data;
      });
      this.mailLogDialog = true;
    },
    // 通知キャンセル処理
    async cancelNotification(item) {
      var result = confirm("通知をキャンセルしてよろしいですか？");
      if (!result) {
        return;
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "commonCancelNotification",
        queId: item.queId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data != "success"){
          alert(ret.data);
        } else {
          alert("キャンセルしました");
        }
      });
      
      this.mailLogDialog = false;
    },
  },
};
</script>

<style scoped>
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

</style>
<template>
  <v-container fluid>
    <v-alert
      v-if="!mailAddressAuthFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      右上の設定をクリックして、メールアドレスの登録をお願いいたします。
    </v-alert>
    <v-alert
      v-if="processingFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      早退された生徒が自宅にお戻りになりましたら、帰宅連絡ボタンを押してください。
    </v-alert>

    <v-row dense v-if="this.isViewSotai">
      <v-col cols="12">
        <v-data-table
          :headers="sotaiheaders"
          :items="sotailist"
          :items-per-page="-1"
          caption="早退連絡"
          dense
          disable-sort
          hide-default-footer
          mobile-breakpoint="0"
        >
          <template #[`header.filterItem`]="{}"> 
            <v-row dense><v-col cols="12" justify="center">
              課程/部/科
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              学年/学級/出席番号
            </v-col></v-row>
          </template>
          <!-- 生徒氏名とモバイル用まとめ -->
          <template #[`item.name`]="{ item }">
            <v-row dense class="hidden-xs-only">
              <v-col cols="12">
                {{ item.name }}
              </v-col>
            </v-row>

            <!-- モバイル用まとめ -->
            <v-row dense class="d-sm-none">
              <v-col cols="12">
                <!-- １行目（生徒氏名＋KBK学年学級出席番号） -->
                <v-row dense>
                  <v-col cols="7" class="text-caption">
                    {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
                  </v-col>
                  <v-col cols="5" class="d-flex justify-end text-caption">
                    {{ item.name }}                    
                  </v-col>
                </v-row>
                <!-- ２行目（早退事由） -->
                <v-row dense>
                  <v-col cols="12" class="text-caption">
                    {{ item.leavingReason }}
                  </v-col>
                </v-row>
                <!-- ３行目（下校開始予定時刻＋早退時刻） -->
                <v-row dense>
                  <v-col cols="4" class="text-caption">
                    予定時刻:{{ item.leavingTime }}
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center text-caption">
                    <span v-if="item.gatePassageTime">早退時刻:{{ item.gatePassageTime }}</span>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    帰宅連絡：<span v-if="item.arrivalStatusFlg">済</span><span v-if="!item.arrivalStatusFlg">未</span>
                  </v-col>
                </v-row>
                <!-- ４行目（ボタン類） -->
                <v-row dense>
                  <v-col cols="6" class="text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickContactSotai(item)">確認</v-btn>
                  </v-col>
                  <v-col cols="6" class="d-flex justify-end text-caption">
                    <v-btn color="accent" small class="black--text" @click="onChangeArrivalStatus(item)" :disabled="item.arrivalStatusFlg">帰宅連絡</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template #[`item.filterItem`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
            </v-col></v-row>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onClickContactSotai(item)">確認</v-btn>
          </template>
          <template #[`item.arrivalStatusFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.arrivalStatusFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.actions2`]="{ item }">
            <v-btn color="accent" class="black--text" large @click="onChangeArrivalStatus(item)" :disabled="item.arrivalStatusFlg">帰宅連絡</v-btn>
          </template>
      </v-data-table>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-tabs fixed-tabs v-model="tab">
          <v-tab href="#contact">
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 0 && unreadContactCount <= 99">{{ unreadContactCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 99">99+</v-btn>
            学校からの連絡
          </v-tab>
          <v-tab href="#survey">
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 0 && unreadSurveysCount <= 99">{{ unreadSurveysCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 99">99+</v-btn>
            アンケート
          </v-tab>
          <v-tab href="#talkRoom">
            <v-btn color="red" small icon outlined v-if="unreadTalkRoomCount > 0 && unreadTalkRoomCount <= 99">{{ unreadTalkRoomCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadTalkRoomCount > 99">99+</v-btn>
            個別連絡
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="contact" eager>
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog1 = true" small>
                  <v-icon right dark v-if="isFilterFlg1">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg1">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue1"
                  label="タイトル検索"
                  placeholder="タイトル検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewUnreadOnly1"
                  @click="changeFilter1(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewFavoriteOnly1"
                  @click="changeFilter1(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="contacts"
              :items-per-page="-1"
              ref="contactTable"
              dense
              disable-sort
              hide-default-footer
              group-by="groupName"
              @click:row="onClickContactDetails"
              :item-class="rowStyleContact"
              :loading="isContactsLoding" loading-text="読込中"
              mobile-breakpoint="0"
            >
              <template #[`group.header`]="{ group, headers, toggle, isOpen, items}">
                <td @click="toggle" :colspan="headers.length">
                  <v-btn icon>
                    <v-icon v-if="isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                  </v-btn>
                  {{ group }}（件数：{{ getCount(items, group) }} 未読：{{ getUnreadCount(items, group) }}）
                </td>
              </template>

              <!-- タイトルとモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(連絡タイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況) -->
                    <v-row no-gutters>
                      <v-col cols="9" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(対象ユーザー区分＋コメント＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                        <v-icon v-if="item.unreadCommentCount > 0" x-small color="red">mdi-new-box</v-icon>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <!-- コメントフラグ -->
              <template #[`item.commentFlg`]="{ item }">
                <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                <v-icon v-if="item.unreadCommentCount > 0" large color="red">mdi-new-box</v-icon>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- 既読/未読 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.unreadState }}
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="survey" eager>
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog2 = true" small>
                  <v-icon right dark v-if="isFilterFlg2">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg2">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue2"
                  label="タイトル検索"
                  placeholder="タイトル検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewUnreadOnly2"
                  @click="changeFilter2(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewFavoriteOnly2"
                  @click="changeFilter2(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="surveyheaders"
              :items="surveylist"
              :items-per-page="-1"
              ref="surveyTable"
              dense
              disable-sort
              hide-default-footer
              group-by="groupName"
              @click:row="onClickSurveyDetails"
              :item-class="rowStyleSurvey"
              :loading="isSurveysLoding" loading-text="読込中"
              mobile-breakpoint="0"
            >
              <template #[`group.header`]="{ group, headers, toggle, isOpen, items}">
                <td @click="toggle" :colspan="headers.length">
                  <v-btn icon>
                    <v-icon v-if="isOpen">mdi-minus</v-icon>
                    <v-icon v-else>mdi-plus</v-icon>
                  </v-btn>
                  {{ group }}（件数：{{ getCount(items, group) }} 未読：{{ getUnreadCount(items, group) }}）
                </td>
              </template>

              <!-- タイトルと締切日とモバイル用まとめ -->
              <template #[`item.title`]="{ item }">
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center" class="red--text" v-if="item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                  <v-col cols="12" justify="center" v-if="!item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                </v-row>

                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        {{ item.groupName }}：{{ item.targetUser }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(アンケートタイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況＋入力状況) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}：
                        {{ item.inputState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(回答期限＋対象ユーザー区分＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        期限:<span v-if="item.limitOverFlg" class="red--text">{{ item.limitDate }}</span>
                        <span v-if="!item.limitOverFlg">{{ item.limitDate }}</span>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              
              <!-- 投稿日と投稿者 -->
              <template #[`item.openDateAndPostUser`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.targetUser }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">
                  <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                  <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                  <div v-if="item.targetSelectFlg">【限定】</div>
                </v-col></v-row>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.inputState }}
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="talkRoom">
            <v-data-table
              :headers="talkRoomHeader"
              :items="talkRoomList"
              :items-per-page="-1"
              ref="talkRoom"
              dense
              disable-sort
              hide-default-footer
              @click:row="onClickTalkRoom"
              :loading="isTalkRoomLoding" loading-text="読込中"
              mobile-breakpoint="0"
            >
              <!-- 更新日と更新者とモバイル用まとめ -->
              <template #[`item.lastMessage`]="{ item }">
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.lastMessageDate }}
                  </v-col>
                </v-row>
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center" v-if="item.lastMessageParentFlg">{{ item.lastMessageUserNm }}</v-col>
                  <v-col cols="12" justify="center" v-if="!item.lastMessageParentFlg" class="red--text">{{ item.lastMessageUserNm }}</v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(最終メッセージ日付) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex text-caption">
                        更新：{{ item.lastMessageDate }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption" v-if="item.lastMessageParentFlg">{{ item.lastMessageUserNm }}</v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption red--text" v-if="!item.lastMessageParentFlg">{{ item.lastMessageUserNm }}</v-col>
                    </v-row>
                    <!-- ２行目(連絡宛先) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-2">
                        {{ item.studentNm }}の保護者様
                      </v-col>
                    </v-row>
                    <!-- ３行目(教員名＋未読件数) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="d-flex text-caption">
                        {{ item.teacherNms }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end">
                        <v-btn color="red" x-small icon outlined v-if="item.unreadFlg">{{ item.unread }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 未読件数 -->
              <template #[`item.unreadFlg`]="{ item }">
                <v-btn color="red" small icon outlined v-if="item.unreadFlg">{{ item.unread }}</v-btn>
              </template>

            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>
    <!-- 連絡フィルタモーダル -->
    <v-dialog
      v-model="filterDialog1"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter1" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter1" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue1"
                label="タイトル検索"
                placeholder="タイトル検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly1"
                @click="changeFilter1(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly1"
                @click="changeFilter1(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- アンケートフィルタモーダル -->
    <v-dialog
      v-model="filterDialog2"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter2" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter2" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue2"
                label="タイトル検索"
                placeholder="タイトル検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly2"
                @click="changeFilter2(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly2"
                @click="changeFilter2(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
export default {
  data() {
    return {
      // 早退明細表示用
      isViewSotai: false,
      sotaiheaders : [
        {text:"氏名", value: "name", class: "hidden-xs-only", width: "13%" },
        {text:"課程/部/科 学年/学級/出席番号", value: "filterItem", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "18%" },
        {text:"早退事由", value: "leavingReason", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        {text:"下校開始予定時刻", value: "leavingTime", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        {text:"早退時刻", value: "gatePassageTime", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        {text:"", value: "actions", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        {text:"帰宅連絡状況", value: "arrivalStatusFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        {text:"", value: "actions2", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
      ],
      sotailist : [],

      tab: 'contact',
      // 連絡
      filterDialog1: false,
      isFilterFlg1: false,
      titleFilterValue1: "",
      viewUnreadOnly1: false,
      viewFavoriteOnly1: false,
      // アンケート
      filterDialog2: false,
      isFilterFlg2: false,
      titleFilterValue2: "",
      viewUnreadOnly2: false,
      viewFavoriteOnly2: false,
      // 個別連絡(フィルター無し)

      headers: [
        { text: "タイトル", value: "title", class: "hidden-xs-only", filter: this.titleFilter1 },
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only"},
        { text: "対象", value: "target", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "openDateAndPostUser", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "コメント", value: "commentFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter1 },
        { text: "既読/未読", value: "unreadFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter1 },
      ],
      contacts: [],
      surveyheaders : [
        { text: "タイトル/回答期限", value: "title", class: "hidden-xs-only", filter: this.titleFilter2 },
        { text: "グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only"},
        { text: "対象", value: "target", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "openDateAndPostUser", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter2 },
        { text: "既読/未読", value: "unreadState", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "入力状況", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter2 },
      ],
      surveylist : [],
      talkRoomHeader1 : [
        { text: "更新日時/更新者", value: "lastMessage", class: "hidden-xs-only", width: "300" },
        { text: "生徒名", value: "studentNm", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "教員", value: "teacherNms", class: "hidden-xs-only", cellClass: "hidden-xs-only" , width: "200" }, 
        { text: "未読件数", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "100" }, 
      ],
      talkRoomHeader2 : [
        { text: "更新日時/更新者", value: "lastMessage", class: "hidden-xs-only", width: "300" },
        { text: "教員", value: "teacherNms", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "200" }, 
        { text: "未読件数", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "100" }, 
      ],
      talkRoomList : [],
      isContactsLoding: false,
      isSurveysLoding: false,
      isTalkRoomLoding: false,
      mailAddressAuthFlg: true,
      processingFlg: false,
      unreadContactCount: 0,
      unreadSurveysCount: 0,
      unreadTalkRoomCount: 0,
      isTargetViewFlg: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    console.log(this.$refs);
    this.tab = this.$store.state.viewConditionTabSelect;
    this.isTargetViewFlg = (this.$root.students.length > 1);
    await this.reload();
  },
  watch: {
    tab: function(newVal) {this.$store.dispatch('setViewConditionTabSelect', newVal)},
  },
  computed: {
    talkRoomHeader () {
      if(this.$root.students.length > 1){
        return this.talkRoomHeader1;
      } else {
        return this.talkRoomHeader2;
      }
    }
  },
  methods: {
    // タイトルフィルタ
    titleFilter(value) {
      if (!this.titleFilterValue) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue);
    },
    // 未読フィルタ
    unreadFilter(value) {
      if (!this.viewUnreadOnly) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    changeFilter(flg){
      if(this.viewUnreadOnly && this.viewFavoriteOnly){
        if(flg){
          this.viewFavoriteOnly = false;
        } else {
          this.viewUnreadOnly = false;
        }
      }
    },
    // 連絡詳細
    onClickContactDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.contactTable.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var contactListBuff = Enumerable.from(items).where(i => i.groupName == item.groupName).select(x => { return x.messageId }).toArray();
      var contactListIndex = contactListBuff.indexOf(item.messageId);

      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId : item.messageId,
          contactListBuff: contactListBuff,
          contactListIndex: contactListIndex,
        }
      });
    },
    // 早退返信
    onClickContactSotai(item) {
      this.$router.push({
        name: "LeavingReply",
        params: {
          id: item.studentId,
        } 
      });
    },
    // アンケート回答
    onClickSurveyDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.surveyTable.$children[0].filteredItems;
      // 調査IDのみ抽出
      var surveyListBuff = Enumerable.from(items).where(i => i.groupName == item.groupName).select(x => { return x.responseId }).toArray();
      var surveyListIndex = surveyListBuff.indexOf(item.responseId);

      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : item.responseId,
          surveyListBuff: surveyListBuff,
          surveyListIndex: surveyListIndex,
        } 
      });
    },
    // 個別連絡
    onClickTalkRoom(item) {
      this.$router.push({
        name: "TalkRoom",
        params: {
          roomId : item.roomId,
        } 
      });
    },
    // ページ再読込
    async reload() {
      var unreadContactCount = 0;
      var unreadSurveysCount = 0;
      var unreadTalkRoomCount = 0;
      // 学校からの連絡取得
      this.contacts = [];
      this.sotailist = [];
      this.surveylist = [];
      this.talkRoomList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isContactsLoding = true;
      this.isSurveysLoding = true;
      this.isTalkRoomLoding = true;
      await schoolAidHomeLogined({funcName:"loadTopPage"}).then((contacts) => {
        var ret = [];
        contacts.data.list.forEach((contact) => {
          var row = {
            contactId: contact.contactId,
            openDate: contact.openDate,
            postUser: contact.postUser,
            groupName: contact.groupName,
            studentFlg: contact.studentFlg,
            parentFlg: contact.parentFlg,
            teacherFlg: contact.teacherFlg,
            title: contact.title,
            messageId: contact.messageId,
            commentFlg: contact.commentFlg,
            unreadFlg: (contact.unreadFlg)?contact.unreadFlg:(contact.unreadCommentCount>0),
            unreadCommentCount: contact.unreadCommentCount,
            favoriteFlg: contact.favoriteFlg,
            targetSelectFlg: contact.targetSelectFlg,
          };
          if(row.unreadFlg){
            row.unreadState = "未読";
            unreadContactCount++;
          } else {
            row.unreadState = "既読";
          }
          ret.push(row);
        });
        this.unreadContactCount = unreadContactCount;
        this.contacts = ret;
        this.mailAddressAuthFlg = contacts.data.mailAddressAuthFlg;
        this.isContactsLoding = false;
      });
      Object.keys(this.$refs.contactTable.openCache).forEach(g => this.$refs.contactTable.openCache[g] = false);
      await schoolAidHomeLogined({funcName:"loadTopPageSurvey"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((survey) => {
          var row = {
            surveyId: survey.surveyId,
            openDate: survey.openDate,
            limitDate: survey.limitDate,
            limitOverFlg: survey.limitOverFlg,
            postUser: survey.postUser,
            targetUser: survey.targetUser,
            groupName: survey.groupName,
            studentFlg: survey.studentFlg,
            parentFlg: survey.parentFlg,
            teacherFlg: survey.teacherFlg,
            title: survey.title,
            responseId: survey.responseId,
            unreadFlg: survey.unreadFlg,
            inputFlg: survey.inputFlg,
            favoriteFlg: survey.favoriteFlg,
            targetSelectFlg: survey.targetSelectFlg,
          };
          if(survey.unreadFlg){
            row.unreadState = "未読";
            unreadSurveysCount++;
          } else {
            row.unreadState = "既読";
          }
          if(survey.inputFlg){
            row.inputState = "入力済";
          } else {
            row.inputState = "未入力";
          }
          ret.push(row);
        });
        this.unreadSurveysCount = unreadSurveysCount;
        this.surveylist = ret;
        this.isSurveysLoding = false;
      });
      Object.keys(this.$refs.surveyTable.openCache).forEach(g => this.$refs.surveyTable.openCache[g] = false);
      this.processingFlg = false;
      schoolAidHomeLogined({funcName:"loadTopPageSotai"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((doc) => {
          var row = {
            name : doc.name,
            kateiNm : doc.kateiNm,
            buNm : doc.buNm,
            kaNm : doc.kaNm,
            gakunen : doc.gakunen,
            gakkyu : doc.gakkyu,
            syussekiNo : doc.syussekiNo,
            leavingReason : doc.leavingReason,
            leavingTime : doc.leavingTime,
            gatePassageTime: doc.gatePassageTime,
            studentId : doc.studentId,
            processingFlg: doc.processingFlg,
            arrivalStatusFlg: doc.arrivalStatusFlg,
          };

          // 帰宅連絡ボタンの警告表示
          if(row.processingFlg == true){
            this.processingFlg = true;
          }

          ret.push(row);
        });
        this.sotailist = ret;
        // 早退確認の表示制御
        if(this.sotailist.length !== 0){
          this.isViewSotai = true;
        } else {
          this.isViewSotai = false;
        }
      });
      schoolAidHomeLogined({funcName:"loadTopPageTalkRoom"}).then((snapshot) => {
        var ret = [];
        snapshot.data.forEach((recode) => {
          var row = {
            roomId: recode.roomId,
            lastMessageDate: recode.lastMessageDate,
            lastMessageUserNm: recode.lastMessageUserNm,
            lastMessageParentFlg: recode.lastMessageParentFlg,
            title: recode.title,
            studentNm: recode.studentNm,
            targetParentFlg: recode.targetParentFlg,
            teacherNms: recode.teacherNms,
            lockFlg: recode.lockFlg,
            total: recode.total,
            read: recode.read,
            unread: (recode.total - recode.read),
            unreadFlg: (recode.total != recode.read),
          };
          // 未読件数の集計
          unreadTalkRoomCount = unreadTalkRoomCount + (recode.total - recode.read);
          ret.push(row);
        });
        this.unreadTalkRoomCount = unreadTalkRoomCount;
        this.talkRoomList = ret;
        this.isTalkRoomLoding = false;
      });
    },
    async onChangeArrivalStatus(item)  {
      var result = confirm("帰宅状況を学校へ連絡しますか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeArrivalStatus",
        studentId: item.studentId,
      };
      await schoolAidHomeLogined(data);
      await this.reload();
    },
    rowStyleContact: function (item) {
      return item.unreadFlg == true ? 'not-yet-style' : 'do-style'
    },
    rowStyleSurvey: function (item) {
      return item.inputFlg == true ? 'do-style' : 'not-yet-style'
    },
    getCount: function (items, group) {
      var count = 0;
      for(let row of items){
        if(row.groupName == group){
          count++;
        }
      }
      return count;
    },
    getUnreadCount: function (items, group) {
      var count = 0;
      for(let row of items){
        if(row.groupName == group && row.unreadFlg == true){
          count++;
        }
      }
      return count;
    },
    // 検索(連絡用)=================================================================================
    // タイトルフィルタ
    titleFilter1(value) {
      if (!this.titleFilterValue1) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue1);
    },
    // 未読フィルタ
    unreadFilter1(value) {
      if (!this.viewUnreadOnly1) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter1(value) {
      if (!this.viewFavoriteOnly1) {
        return true;
      }
      return value;
    },
    changeFilter1(flg){
      if(this.viewUnreadOnly1 && this.viewFavoriteOnly1){
        if(flg){
          this.viewFavoriteOnly1 = false;
        } else {
          this.viewUnreadOnly1 = false;
        }
      }
    },
    // 検索(アンケート用)=================================================================================
    // タイトルフィルタ
    titleFilter2(value) {
      if (!this.titleFilterValue2) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue2);
    },
    // 未読フィルタ
    unreadFilter2(value) {
      if (!this.viewUnreadOnly2) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter2(value) {
      if (!this.viewFavoriteOnly2) {
        return true;
      }
      return value;
    },
    changeFilter2(flg){
      if(this.viewUnreadOnly2 && this.viewFavoriteOnly2){
        if(flg){
          this.viewFavoriteOnly2 = false;
        } else {
          this.viewUnreadOnly2 = false;
        }
      }
    },

    // 検索モーダル用 =================================================================================
    doFilter1() {
      this.isFilterFlg1 = true;
      this.filterDialog1 = false;
    },
    clearFilter1(){
      this.titleFilterValue1 = "";
      this.viewUnreadOnly1 = false;
      this.viewFavoriteOnly1 = false;

      this.isFilterFlg1 = false;
      this.filterDialog1 = false;
    },
    doFilter2() {
      this.isFilterFlg2 = true;
      this.filterDialog2 = false;
    },
    clearFilter2(){
      this.titleFilterValue2 = "";
      this.viewUnreadOnly2 = false;
      this.viewFavoriteOnly2 = false;

      this.isFilterFlg2 = false;
      this.filterDialog2 = false;
    },
  },  
};
</script>

<style>
.do-style {
  background-color: rgb(182, 182, 182)
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
</style>
<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          課題一覧(所属グループ分)
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 課題登録者検索 -->
        <v-autocomplete
          v-model="groupFilterValue"
          label="ｸﾞﾙｰﾌﾟ名検索"
          placeholder="ｸﾞﾙｰﾌﾟ名検索"
          :items="groups"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="titleFilterValue"
          label="課題名検索"
          placeholder="課題名検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 検索用の年度入力 -->
        <v-autocomplete
          v-model="selectNendo"
          label="年度指定"
          placeholder="年度指定"
          :items="nendoList"
          @change="reload"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-switch 
          v-model="viewFavoriteOnly"
          dense
          class="mt-1"
          label="お気に入りのみ"
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="homeworks"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isHomeworksLoding" loading-text="読込中"
          >
          <!-- 投稿日と対象グループ -->
          <template #[`item.groupName`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.postDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
          </template>
          <!-- 課題名と期限 -->
          <template #[`item.title`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.title }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.limitDate }}</v-col></v-row>
          </template>
          <!-- 活動記録一覧 -->
          <template #[`item.activityRecodes`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-btn  color="accent" class="black--text" @click="onClickARListItem(item)">
                  一覧
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <!-- お気に入り -->
          <template #[`item.favoriteFlg`]="{ item }">
            <v-tooltip top v-if="item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteHomework(item)"><v-icon color="orange">mdi-star</v-icon></v-btn>
              </template>
              <span>お気に入りを解除する</span>
            </v-tooltip>
            <v-tooltip top v-if="!item.favoriteFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon outlined v-bind="attrs" v-on="on" @click="changeFavoriteHomework(item)"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <span>お気に入りに追加する</span>
            </v-tooltip>
          </template>
          <!-- 編集と通知 -->
          <template #[`item.editAndMail`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickEditItem(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>課題を編集する</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" justify="center" style="white-space : nowrap;">
                <v-tooltip bottom v-if="tounendo == selectNendo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="openMailModal(item)">
                      <v-icon>mdi-email-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>通知予約を登録する</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickMailLog(item)">
                      <v-icon>mdi-email-search-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>通知状況を確認する</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <!-- 公開期間 -->
          <template #[`item.publishDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.openDate }}～</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.closeDate }}</v-col></v-row>
          </template>
          <!-- 公開状態 -->
          <template #[`item.isOpen`]="{ item }">
            <v-btn color="accent" class="black--text" v-if="item.publishFlg && tounendo == selectNendo && !item.beforeOpeningFlg" @click="onClickChangeOpenStatusHomework(item)">公開中</v-btn>
            <v-btn color="white" class="black--text" v-if="item.publishFlg && tounendo == selectNendo && item.beforeOpeningFlg" @click="onClickChangeOpenStatusHomework(item)">公開前</v-btn>
            <v-btn color="grey" class="black--text" v-if="!item.publishFlg && tounendo == selectNendo" @click="onClickChangeOpenStatusHomework(item)">停止中</v-btn>
            <v-btn color="grey" class="black--text" v-if="tounendo != selectNendo" :disable="true">停止中</v-btn>
          </template>
          <!-- ユーザー閲覧数 -->
          <template #[`item.count`]="{ item }">
            {{ item.count }}
          </template>
          <!-- 操作 -->
          <template #[`item.action`]="{ item }">
            <v-row dense>
              <v-col cols="12" justify="center">
                <v-tooltip bottom v-if="tounendo == selectNendo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>課題を削除する</span>
                </v-tooltip>
                <v-tooltip bottom v-if="tounendo != selectNendo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon outlined v-bind="attrs" v-on="on" :disable="true">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>課題を削除する</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- メール送信モーダル -->
    <v-dialog
      v-model="mailDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="ml-12">課題を通知する</v-card-title>
        <v-list-item-subtitle class="ml-3" style="font-size:.835rem;">非公開の場合は公開になり次第通知されます</v-list-item-subtitle>
        <v-card-text>
          <v-radio-group v-model="mailType" :mansdatory="false">
            <v-radio label="課題の登録を通知する" value="P-0"></v-radio>
            <v-radio label="課題の修正を通知する" value="P-1"></v-radio>
            <v-radio label="未読者に対して再通知する" value="P-2"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="grey" @click="mailDialog = false">
                閉じる
              </v-btn>
            </v-col>
            <v-col cols="3" justify="center"></v-col>
            <v-col cols="4" class="ml-1" justify="center">
              <v-btn block color="accent" @click="sendMailHomework()">
                送信予約
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 通知履歴モーダル -->
    <v-dialog
      v-model="mailLogDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="pl-5" style="font-size:1.012rem;">通知履歴</v-card-title>
        <v-card-subtitle class="pl-5">※完了まで数分かかる場合があります</v-card-subtitle>
        <v-card-subtitle class="pl-5">※通知可能なメールもしくはスマホアプリを１件としてカウントしています</v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="mailLogHeader"
            :items="mailLogList"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
          >
          <template #[`item.cancel`]="{ item }">
            <v-btn color="green" small class="white--text" v-if="item.status == '0' || item.status == '1'" @click="cancelNotification(item)">キャンセル</v-btn>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      // 対象学級フィルター用
      groupFilterValue: "",
      groups : [],
      // 課題名フィルター用
      titleFilterValue: "",
      // 年度フィルター
      tounendo: "",
      selectNendo: "",
      nendoList : [],
      // お気に入りフィルター用
      viewFavoriteOnly: false,
      headers: [
        { text: "作成日時/対象学級", value: "groupName", width: "200", filter: this.groupFilter },  
        { text: "配信課題名/期限", value: "title", filter: this.titleFilter},
        { text: "お気に入り", value: "favoriteFlg", width: "90", filter: this.favoriteFilter },
        { text: "編集/通知", value: "editAndMail", width: "90" },
        { text: "配信期間", value: "publishDate", width: "200" },
        { text: "公開状態", value: "isOpen", width: "5%" },
        { text: "活動記録", value: "activityRecodes", width: "5%" },
        { text: "全体：未読：既読：提出数", value: "count", width: "180" },
        { text: "操作", value: "action", width: "80" },
      ],
      homeworks: [],
      isHomeworksLoding: false,
      mailDialog: false,
      mailHomeworkId: "",
      mailType: "P-1",
      mailLogDialog: false,
      mailLogHeader: [
        { text: "登録日時", value: "insDate"},
        { text: "通知区分", value: "typeNm"},
        { text: "通知設定者", value: "postUser"},
        { text: "状態", value: "statusNm"},
        { text: "全件数", value: "allCount"},
        { text: "通知済件数", value: "doneCount"},
        { text: "未通知件数", value: "notYetCount"},
        { text: "通知完了日時", value: "updDate"},
        { text: "キャンセル", value: "cancel"},
      ],
      mailLogList: [],
      perPage: -1,
    }
  },
  methods: {
    // 初期処理
    async setup() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName: "commonGetNendo"}).then((ret) => {
        this.tounendo = ret.data.tounendo;
        this.selectNendo = ret.data.tounendo;
        this.nendoList = ret.data.list;
      });
      await this.reload();
    },
    // 投稿者名フィルタ
    groupFilter(value) {
      if (!this.groupFilterValue) {
        return true;
      }
      return value.toString().includes(this.groupFilterValue);
    },
    // 課題名フィルタ
    titleFilter(value) {
      if (!this.titleFilterValue) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue);
    },
    // お気に入りフィルタ
    favoriteFilter(value) {
      if (!this.viewFavoriteOnly) {
        return true;
      }
      return value;
    },
    // お気に入りアイコン
    async changeFavoriteHomework(item)  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var param = {
        funcName: "changeFavoriteHomeworks",
        homeworkId: item.homeworkId,
        favoriteFlg: item.favoriteFlg
      };
      await schoolAidHomeLogined(param);
      item.favoriteFlg = !item.favoriteFlg;
    },
    // リロードボタン
    async reload() {
      var groups = new Set();
      this.homeworks = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isHomeworksLoding = true;
      var param = {
        funcName:"poLoadHomeworkListForSelf",
        nendo: this.selectNendo
      }
      await schoolAidHomeLogined(param).then((homeworks) => {
        var ret = [];
        homeworks.data.forEach((homework) => {
          groups.add(homework.groupName);
          var count = homework.allCount + "：" + homework.unreadCount + "：" + homework.readCount + "：" + homework.submissionCount  ;
          var row = {
            postDate: homework.postDate,
            postUser: homework.postUser,
            groupName: homework.groupName,
            title : homework.title,
            openDate: homework.openDate,
            limitDate : homework.limitDate,
            closeDate: homework.closeDate,
            publishFlg : homework.publishFlg,
            count : count,
            allCount : homework.allCount,
            homeworkId : homework.homeworkId,
            favoriteFlg: homework.favoriteFlg,
            beforeOpeningFlg: homework.beforeOpeningFlg,
          };

          ret.push(row);
        });
        this.homeworks = ret;
        this.isHomeworksLoding = false;
        this.groups = Array.from(groups);
      });
    },
    // 活動記録リスト
    onClickARListItem(item) {
      this.$router.push({
        name: "PoActivityRecordListForHomework",
        params: {
          homeworkId: item.homeworkId,
          backPageName: 'PoHomeworkListForSelf'
        }
      });
    },
    // 課題修正
    onClickEditItem(item) {
      this.$router.push({
        name: "PoHomeworkEdit",
        params: {
          homeworkId: item.homeworkId,
          backPageName: 'PoHomeworkListForSelf'
        }
      });
    },
    // 通知送信モーダルを開く
    openMailModal(item) {
      this.mailHomeworkId = item.homeworkId;
      this.mailType = "P-1";
      this.mailDialog = true;
    },
    // 通知処理
    async sendMailHomework() {
      var result = confirm("通知してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poSendMail",
        homeworkId: this.mailHomeworkId,
        mailType: this.mailType,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then(() => {
        alert("受け付けました");
      });
      // ローディング終了
      this.$root.loading = false;
      this.mailDialog = false;
    },
    // 公開状態変更
    async onClickChangeOpenStatusHomework(item) {
      var result = confirm("公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poChangeOpenStatus",
        homeworkId: item.homeworkId,
        publishFlg: item.publishFlg,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },
    // 論理削除
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poDeleteHomework",
        homeworkId: item.homeworkId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.reload();
    },
    // 通知履歴確認
    async onClickMailLog(item) {
      var param = {
        funcName: "commonGetMailLog",
        id: item.homeworkId,
        type: "P",
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        this.mailLogList = ret.data;
      });
      this.mailLogDialog = true;
    },
    // 通知キャンセル処理
    async cancelNotification(item) {
      var result = confirm("通知をキャンセルしてよろしいですか？");
      if (!result) {
        return;
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "commonCancelNotification",
        queId: item.queId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data != "success"){
          alert(ret.data);
        } else {
          alert("キャンセルしました");
        }
      });
      
      this.mailLogDialog = false;
    },
  },
}
</script>
<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          出欠集計確認
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="left">
        <v-card class="mx-auto text-left" color="white">
          課程・部・科を指定し、集計を行ってください。(※学年・学級は任意指定となります)
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="white">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <v-row dense>
              <v-col cols="3" justify="center">
                <ValidationProvider
                  name="課程"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="課程"
                    :items="kateiMst"
                    prepend-icon="$cet_required"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedKatei"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <ValidationProvider
                  name="部"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="部"
                    :items="buMst"
                    prepend-icon="$cet_required"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedBu"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="3" justify="center">
                <ValidationProvider
                  name="科"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="科"
                    :items="kaMst"
                    prepend-icon="$cet_required"
                    @change="changeKBK"
                    item-text="name"
                    item-value="code"
                    v-model="selectedKa"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="学年"
                  :items="gakunenMst"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakunen"
                  outlined
                  dense
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
              </v-col>
              <v-col cols="2" justify="center">
                <v-select
                  label="学級"
                  :items="gakkyuMst"
                  item-text="name"
                  item-value="name"
                  v-model="selectedGakkyu"
                  outlined
                  dense
                  clearable
                  multiple
                  chips
                  deletable-chips
                  :disabled="isSearch"
                  hide-details="auto"
                  ></v-select>
              </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3" justify="center">
              <ValidationProvider name="集計開始日" rules="required" v-slot="{ errors }">
                <v-text-field
                    v-model="fromDate"
                    prepend-icon="$cet_required"
                    label="集計開始日"
                    placeholder=""
                    :error-messages="errors[0]"
                    type="date"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                    background-color="white"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="3" justify="center">
              <ValidationProvider name="集計終了日" rules="required" v-slot="{ errors }">
                <v-text-field
                    v-model="toDate"
                    prepend-icon="$cet_required"
                    label="集計終了日"
                    placeholder=""
                    type="date"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :disabled="isSearch"
                    hide-details="auto"
                    background-color="white"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="2" justify="center">
              <!-- 土日を含む -->
              <v-switch 
                v-model="isIncludeWeekend"
                dense
                class="mt-1"
                label="土日を含む "
                hide-details="auto"
              />
            </v-col>
            <v-col cols="1" justify="center">
              <v-btn color="grey" class="mx-auto mt-0 black--text" @click="onClear" :disabled="!isSearch" width="90%">クリア</v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn color="green" class="mx-auto black--text" :disabled="isExportingFlg||invalid" :loading="isExportingFlg" @click="exportLatecomerList()" width="90%">遅刻者一覧出力<v-icon right dark>mdi-file-excel</v-icon></v-btn>
            </v-col>
            <v-col cols="1" justify="center">
              <v-btn color="accent" class="mx-auto mt-0 black--text" @click="onSearch" :disabled="isExportingFlg||isSearch||invalid" width="90%">集計</v-btn>
            </v-col>
          </v-row>
          </ValidationObserver>
        </v-card> 
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="summary"
          :items-per-page="-1"
          hide-default-footer
          ref="table"
          dense
          disable-sort
          group-by="groupName"
          :fixed-header="true"
          :loading="isListLoding" loading-text="読込中"
        >
          <template #[`group.header`]="{ group, items, headers, toggle, isOpen}">
            <td @click="toggle" :colspan="headers.length-2">
              <v-btn icon>
                <v-icon v-if="isOpen">mdi-minus</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </v-btn>
              {{ group }}
            </td>
            <td colspan="2">
              合計
              未確定[欠席:{{ getUnconfirmedKessekiTotal(items) }} 遅刻:{{ getUnconfirmedChikokuTotal(items) }} 早退:{{ getUnconfirmedSotaiTotal(items) }}]
              確認済[欠席:{{ getConfirmedKessekiTotal(items) }} 遅刻:{{ getConfirmedChikokuTotal(items) }} 早退:{{ getConfirmedSotaiTotal(items) }} 出停:{{ getConfirmedSyutteiTotal(items) }} 忌引:{{ getConfirmedKibikiTotal(items) }}]
            </td>
          </template>
          <template #[`item.dayKbn`]="{ item }">
            <span v-if="item.dayKbn == 0" class="red--text">{{ item.displayDate }}</span>
            <span v-else-if="item.dayKbn == 6" class="blue--text">{{ item.displayDate }}</span>
            <span v-else>{{ item.displayDate }}</span>
          </template>
          <template #[`item.unconfirmed`]="{ item }">
            <v-row dense>
              <v-col cols="4" justify="center">
                <span v-if="item.unconfirmedKesseki > 0" class="red--text">欠席:{{ item.unconfirmedKesseki }}</span>
                <span v-else>欠席:0</span>
              </v-col>
              <v-col cols="4" justify="center">
                <span v-if="item.unconfirmedChikoku > 0" class="red--text">遅刻:{{ item.unconfirmedChikoku }}</span>
                <span v-else>遅刻:0</span>
              </v-col>
              <v-col cols="4" justify="center">
                <span v-if="item.unconfirmedSotai > 0" class="red--text">早退:{{ item.unconfirmedSotai }}</span>
                <span v-else>早退:0</span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.confirmed`]="{ item }">
            <v-row dense>
              <v-col cols="3" justify="center">
                <span v-if="item.confirmedKesseki > 0" class="red--text">欠席:{{ item.confirmedKesseki }}</span>
                <span v-else>欠席:0</span>
              </v-col>
              <v-col cols="3" justify="center">
                <span v-if="item.confirmedChikoku > 0" class="red--text">遅刻:{{ item.confirmedChikoku }}</span>
                <span v-else>遅刻:0</span>
              </v-col>
              <v-col cols="2" justify="center">
                <span v-if="item.confirmedSotai > 0" class="red--text">早退:{{ item.confirmedSotai }}</span>
                <span v-else>早退:0</span>
              </v-col>
              <v-col cols="2" justify="center">
                <span v-if="item.confirmedSyuttei > 0" class="red--text">出停:{{ item.confirmedSyuttei }}</span>
                <span v-else>出停:0</span>
              </v-col>
              <v-col cols="2" justify="center">                
                <span v-if="item.confirmedKibiki > 0" class="red--text">忌引:{{ item.confirmedKibiki }}</span>
                <span v-else>忌引:0</span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn color="accent" class="black--text" @click="onClickOpenDialog(item)">詳細</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="onSearch" :disabled="!isSearch" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 詳細モーダル -->
    <v-dialog
      v-model="isOpenDialog"
      max-width="1000"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="1000"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center" white-space="pre">
          <p>出欠詳細[{{ dialogGroupName }}：{{ dialogDisplayDate }}]</p>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-column">
          <v-data-table
            :headers="searchHeaders"
            :items="searchResult"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
            class="elevation-1"
            :loading="isDialogLoding" loading-text="読込中"
          >
          <template #[`item.unconfirmedKbnName`]="{ item }">
            <v-row dense>
              <v-col cols="6" justify="center">
                {{ item.unconfirmedSyukketuKbn }}
              </v-col>
              <v-col cols="6" justify="center">
                {{ item.unconfirmedSotaiKbn }}
              </v-col>
            </v-row>
          </template>
          <template #[`item.confirmedKbnName`]="{ item }">
            <v-row dense>
              <v-col cols="6" justify="center">
                {{ item.confirmedSyukketuKbn }}
              </v-col>
              <v-col cols="6" justify="center">
                {{ item.confirmedSotaiKbn }}
              </v-col>
            </v-row>
          </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" class="black--text" @click="isOpenDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { parse } from 'date-fns';
import Enumerable from "linq";
import { saveAsCommon } from '../../common';
export default {
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      // KBK制御
      kateiMst: [],
      buMst: [],
      kaMst: [],
      gakunenMst: [],
      gakunenMstBase: [],
      gakkyuMst: [],
      gakkyuMstBase: [],
      selectedKatei: "01",
      selectedBu: "",
      selectedKa: "",
      selectedGakunen: [],
      selectedGakkyu: [],
      fromDate: "",
      toDate: "",
      isIncludeWeekend: true,
      isSearch: false,
      headers: [
        { text: "グループ", value: "groupName", width: "0%" },
        { text: "日付", value: "dayKbn", width: "15%" , filter: this.weekendFilter},
        { text: "未確定", value: "unconfirmed", width: "25%" },
        { text: "確定済", value: "confirmed", width: "50%" },
        { text: "", value: "action", width: "10%" },
      ],
      summary: [],
      isListLoding: false,

      dialogGroupName: "",
      dialogDisplayDate: "",
      searchHeaders: [
        { text: "学年", value: "gakunen", width: "10%" },  
        { text: "学級", value: "gakkyu", width: "10%" },
        { text: "出席番号", value: "syussekiNo", width: "10%" },
        { text: "氏名", value: "userNm", width: "40%" },
        { text: "未確定区分", value: "unconfirmedKbnName", width: "20%" },
        { text: "確定区分", value: "confirmedKbnName", width: "20%" },
      ],
      searchResult:[],

      isOpenDialog: false,
      isDialogLoding: false,
      isExportingFlg: false,
    };
  },
  methods: {
    // 初期処理
    async setup() {
      // KBKGCの取得
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName:"commonGetKBKG"}).then((ret) => {
        this.kateiMst = ret.data.kateiMst;
        this.buMst = ret.data.buMst;
        this.kaMst = ret.data.kaMst;
        this.gakunenMstBase = ret.data.gakunenMst;
        this.gakkyuMstBase = ret.data.gakkyuMst;
      });
      // 日付の初期設定
      this.defaultDateSet();
    },
    // 検索条件日付の初期設定１
    defaultDateSet() {
      var dt = new Date() ;
      const dtFrom = new Date(dt.setMonth(dt.getMonth() - 1));
      this.fromDate = this.formatDate(dtFrom);
      this.toDate = this.formatDate(new Date());
    },
    // 検索条件日付の初期設定２
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },
    // 課程・部・科設定後のセレクトボックス変更
    changeKBK() {
      if(this.selectedKatei
       && this.selectedBu
       && this.selectedKa){
        // 学年マスタをKBKでフィルタ
        this.gakunenMst = Enumerable.from(this.gakunenMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.selectedGakunen = [];
        // 学級マスタをKBKでフィルタ
        this.gakkyuMst = Enumerable.from(this.gakkyuMstBase).where(i => 
        i.code.substring(0, 8) == (
          this.selectedKatei
          + "-"
          + this.selectedBu
          + "-"
          + this.selectedKa
          )).toArray();
        this.selectedGakkyu = [];
      }
    },
    // クリア
    async onClear() {
      this.summary = [];
      this.isSearch = false;
    },
    // 集計処理
    async onSearch() {

      const fromDate = parse(this.fromDate, 'yyyy-MM-dd', new Date());
      const toDate = parse(this.toDate, 'yyyy-MM-dd', new Date());
      // 日付の前後チェック
      if(fromDate.getTime() > toDate.getTime()){
        alert('集計開始日は終了日より未来日で指定してください');
        return;
      }

      const data = {
        funcName: "loadAttendanceSummary",
        katei: this.selectedKatei,
        bu: this.selectedBu,
        ka: this.selectedKa,
        gakunen: this.selectedGakunen,
        gakkyu: this.selectedGakkyu,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      this.summary = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((recode) => {
          buff.push(recode);
        });
        this.summary = buff;
        this.isListLoding = false;
      });
      this.isSearch = true;
      Object.keys(this.$refs.table.openCache).forEach(g => this.$refs.table.openCache[g] = false);
    },
    // 土日含むフィルタ
    weekendFilter(value) {
      if(this.isIncludeWeekend){
        return true;
      }
      return !(value == 0 || value == 6);
    },
    // ダイアログ表示処理
    async onClickOpenDialog(item){
      console.log(item);
      this.dialogGroupName = item.groupName;
      this.dialogDisplayDate = item.displayDate;
      const data = {
        funcName: "loadAttendanceSummaryDetail",
        katei: this.selectedKatei,
        bu: this.selectedBu,
        ka: this.selectedKa,
        groupKey: item.groupKey,
        yyyymmdd: item.yyyymmdd,
      };
      this.searchResult = [];
      this.isOpenDialog = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isDialogLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((recode) => {
          buff.push(recode);
        });
        this.searchResult = buff;
        this.isDialogLoding = false;
      });
    },
    // グループ小計(未確認・欠席)
    getUnconfirmedKessekiTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.unconfirmedKesseki
      }, 0)
      return total
    },
    // グループ小計(未確認・遅刻)
    getUnconfirmedChikokuTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.unconfirmedChikoku
      }, 0)
      return total
    },
    // グループ小計(未確認・早退)
    getUnconfirmedSotaiTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.unconfirmedSotai
      }, 0)
      return total
    },
    // グループ小計(確認済・欠席)
    getConfirmedKessekiTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.confirmedKesseki
      }, 0)
      return total
    },
    // グループ小計(確認済・遅刻)
    getConfirmedChikokuTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.confirmedChikoku
      }, 0)
      return total
    },
    // グループ小計(確認済・早退)
    getConfirmedSotaiTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.confirmedSyuttei
      }, 0)
      return total
    },
    // グループ小計(確認済・出停)
    getConfirmedSyutteiTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.confirmedKibiki
      }, 0)
      return total
    },
    // グループ小計(確認済・忌引)
    getConfirmedKibikiTotal(items) {
      let total = 0
      total = items.reduce((prev, current) => {
        return prev + current.confirmedSotai
      }, 0)
      return total
    },
    // エクセル出力処理
    async exportLatecomerList() {
      var result = confirm("出力してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.isExportingFlg = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      
      var param = {
        funcName: "exportLatecomerList",
        katei: this.selectedKatei,
        bu: this.selectedBu,
        ka: this.selectedKa,
        gakunen: this.selectedGakunen,
        gakkyu: this.selectedGakkyu,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
          alert("ダウンロードを開始します。\nダウンロードフォルダをご確認ください。\n※ファイルを確認出来ない場合はポップアップがブロックされていないか\nご確認ください。");
        }
      });
      // ローディング終了
      this.isExportingFlg = false;
    },
  },
};
</script>

<style>
.calender-text-box-wrapper{
}
.calender-text-box {
    display: none;
}
.vdp-datepicker__calendar {
    width: 100% !important;
}
</style>
<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="1" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="10" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8">
              <div align="center">
                {{ groupName }}の出欠早退状況({{ yyyymmddDisp }})
              </div>
            </v-col>
            <v-col cols="4">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" sm="6" md="6" lg="6" xl="6">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="search"
          label="氏名ｶﾅ検索"
          placeholder="氏名ｶﾅ検索"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" md="6" lg="6" xl="6">

        <v-radio-group
          v-model="viewChange"
          row
          hide-details="auto"
        >
          <v-radio
            label="通常"
            value="1"
          ></v-radio>
          <v-radio
            label="パスワード初期化"
            value="2"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="showHeaders"
          :items="members"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          :loading="isListLoding" loading-text="読込中"
          :search="search"
          :custom-filter="filter"
          >
          <template #[`item.userNm`]="{ item }">
            {{ item.userNm }}({{ item.userNmKana }})
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="blue" icon outlined v-bind="attrs" v-on="on" @click="viewAttendanceSummaryForStudent(item)"><v-icon>mdi-information-outline</v-icon></v-btn>
              </template>
              <span>生徒の今年度の出欠状況集計</span>
            </v-tooltip>
          </template>

          <template #[`item.talkRoom`]="{ item }">
            <v-tooltip bottom v-if="!item.existsFlg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" icon outlined v-bind="attrs" v-on="on" @click="onClickTalkRoomDialog(item)"><v-icon>mdi-chat-plus</v-icon></v-btn>
              </template>
              <span>保護者へ個別連絡</span>
            </v-tooltip>
          </template>

          <template #[`header.KBKGCS`]="{}"> 
            <v-row dense><v-col cols="12" justify="center">
              課程/部/科
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              学年/学級/出席番号
            </v-col></v-row>
          </template>
          <template #[`item.KBKGCS`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
            </v-col></v-row>
          </template>

          <!-- 通常 -->
          <template #[`item.syukketuInputFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.syukketuInputFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action1`]="{ item }">
            <v-btn color="accent" small class="black--text" :disabled="item.pastLockFlg" @click="onClickSyukketu(item)">出欠登録</v-btn>
          </template>
          <template #[`item.syukketuApprovalFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.syukketuApprovalFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action2`]="{ item }">
            <v-btn color="accent" small class="black--text" :disabled="pastLock(item, true)" @click="onClickSyukketuApproval(item)">出欠確定</v-btn>
          </template>
          <template #[`item.sotaiInputFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.sotaiInputFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action3`]="{ item }">
            <v-btn color="blue" small class="black--text" :disabled="item.pastLockFlg" @click="onClickSoutai(item)">早退登録</v-btn>
          </template>
          <template #[`item.sotaiApprovalFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.sotaiApprovalFlg">mdi-check-outline</v-icon>
          </template>
          <template #[`item.action4`]="{ item }">
            <v-btn color="blue" small class="black--text" :disabled="pastLock(item, false)" @click="onClickSoutaiApproval(item)">早退確定</v-btn>
          </template>

          <!-- パスワード初期化 -->
          <!-- 生徒初回ログイン -->
          <template #[`item.consentFlg`]="{ item }">
            <span v-if="item.consentFlg">済</span>
            <span v-else class="red--text">未</span>
          </template>
          <!-- 保護者初回ログイン -->
          <template #[`item.parentConsentFlg`]="{ item }">
            <span v-if="item.parentConsentFlg">済</span>
            <span v-else class="red--text">未</span>
          </template>
          <!-- メールアドレス設定状況 -->
          <template #[`item.mailAddressAuthFlg`]="{ item }">
            <v-row no-gutters>
              <v-col cols="12" class="text-caption">
                生徒１：{{ item.mailAddress }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-caption">
                生徒２：{{ item.mailAddress2 }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-caption">
                保護者１：{{ item.parentMailAddress }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-caption">
                保護者２：{{ item.parentMailAddress2 }}
              </v-col>
            </v-row>
          </template>
          <!-- パスワード変更状況 -->
          <template #[`item.passwordChangeFlg`]="{ item }">
            <v-row no-gutters>
              <v-col cols="12" class="text-caption">
                生徒：<span v-if="item.passwordChangeFlg">済</span>
                      <span v-else class="red--text">未</span>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-caption">
                保護者：<span v-if="item.parentPasswordChangeFlg">済</span>
                      <span v-else class="red--text">未</span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.action5`]="{ item }">
            <v-btn color="blue" small class="black--text" @click="onClickResetDialog(item)">パスワード初期化</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="justify-start" dense>
      <v-col>
        <v-btn @click="onClicktoGroupSelect" color="grey" class="black--text" width="352">戻る</v-btn>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- モーダル類********************************************************************************************** -->
    <!-- 個人単位出欠集計(今年度月別)モーダル -->
    <v-dialog
      v-model="summaryDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="pl-5" style="font-size:1.012rem;">個人単位出欠集計(今年度月別)</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="summaryHeader"
            :items="summaryList"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 個別連絡作成モーダル -->
    <v-dialog
      v-model="talkRoomDialog"
      max-width="800"
      persistent
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="talkRoomDialog = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" justify="center">
                <p>{{ talkRoomStudentNm }}の保護者との個別連絡を作成</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="accent" class="black--text" @click="openTalkRoom()" :disabled="invalid" width="90%">
                  作成
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-autocomplete
                  v-model="talkRoomAssign"
                  label="やりとりに参加する教員(自身以外)"
                  placeholder="やりとりに参加する教員(自身以外)"
                  :items="teacherList"
                  outlined
                  dense
                  multiple
                  deletable-chips
                  small-chips
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- 出欠確定モーダル -->
    <v-dialog
      v-model="attendanceApprovalDialog"
      max-width="500"
      persistent
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="500"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>{{ yyyymmddDisp }}の出欠確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-select
                    label="出欠登録"
                    item-text="label"
                    item-value="syukketuKbn"
                    v-model="selectedSyukketu"
                    :items="syukketu"
                    outlined
                    disabled
                    hide-details="auto"
                  ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-text-field
                  v-model="biko"
                  label="備考"
                  placeholder="備考"
                  outlined
                  dense
                  :disabled="true"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      prepend-icon="$cet_required"
                      v-model="selectedMain"
                      :items="selectMain"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      prepend-icon="$cet_required"
                      v-model="approvalKbn"
                      :items="selectSubFilter"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    label="その他理由"
                    placeholder="その他理由"
                    prepend-icon="$cet_required"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="500"
                    hide-details="auto"
                    v-if="approvalKbn.endsWith('99')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="attendanceApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="syukketuApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- 早退確定モーダル -->
    <v-dialog
      v-model="leavingApprovalDialog"
      max-width="500"
      persistent
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="500"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>{{ yyyymmddDisp }}の早退確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-text-field
                  v-model="leavingReason"
                  label="早退事由"
                  placeholder="早退事由"
                  outlined
                  dense
                  :disabled="true"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-text-field
                  v-model="leavingTime"
                  label="下校開始予定時刻"
                  placeholder="下校開始予定時刻"
                  outlined
                  dense
                  :disabled="true"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      prepend-icon="$cet_required"
                      v-model="selectedMain"
                      :items="selectMain"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      prepend-icon="$cet_required"
                      v-model="approvalKbn"
                      :items="selectSubFilter"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    prepend-icon="$cet_required"
                    label="その他理由"
                    placeholder="その他理由"
                    outlined
                    :error-messages="errors[0]"
                    dense
                    :counter="500"
                    v-if="approvalKbn.endsWith('99')"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="leavingApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="sotaiApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- パスワードリセットダイアログ -->
    <v-dialog
      v-model="resetDialog"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title class="justify-center">パスワードの初期化</v-card-title>
        <v-card-text>
          初期化された後はアカウント通知書に記載のパスワードとなります
          <v-radio-group v-model="resetType" :mansdatory="false">
            <v-radio label="生徒のパスワード" value="0"></v-radio>
            <v-radio label="保護者のパスワード" value="1"></v-radio>
          </v-radio-group>
          <v-btn block color="accent" @click="doReset()">
            初期化する
          </v-btn>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-btn block color="grey" @click="resetDialog = false">
                閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
  props: ['groupName','groupId','param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      console.log(newVal, oldVal)
      await this.reload();
    },
    perPage: function(newVal) {
      this.$store.dispatch('setParPageUserList', newVal);
    },
  },
  computed: {
    showHeaders () {
      if(this.viewChange == "1"){
        return this.headers1;
      } else {
        return this.headers2;
      }
    }
  },
  data() {
    return {
      // 日付選択用
      yyyymmdd: "",
      yyyymmddDisp: "",
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },

      // 明細絞り込み用
      search: "",
      // 明細表示内容制御用
      viewChange: "1",
      headers1: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "200" },
        { text: "", value: "talkRoom", width: "5%" },
        { text: "課程/部/科 学年/学級/出席番号", value: "KBKGCS", width: "200" },
        { text: "出欠入力", value: "syukketuInputFlg", width: "5%" },
        { text: "", value: "action1", width: "5%" },
        { text: "出欠確定", value: "syukketuApprovalFlg", width: "5%" },
        { text: "", value: "action2", width: "5%" },
        { text: "早退入力", value: "sotaiInputFlg", width: "5%" },
        { text: "", value: "action3", width: "5%" },
        { text: "早退確定", value: "sotaiApprovalFlg", width: "5%" },
        { text: "", value: "action4", width: "5%" },
      ],
      headers2: [
        { text: "氏名(ｶﾅ)", value: "userNm", align: "start", width: "200" },
        { text: "課程/部/科 学年/学級/出席番号", value: "KBKGCS", width: "200" },
        { text: "生徒初回ログイン", value: "consentFlg", width: "87" },
        { text: "保護者初回ログイン", value: "parentConsentFlg", width: "95" },
        { text: "メール認証状況", value: "mailAddressAuthFlg" },
        { text: "パスワード変更状況", value: "passwordChangeFlg", width: "100" },
        { text: "", value: "action5", width: "165" },
      ],
      members: [],
      isListLoding: false,
      perPage: 30,

      // 個人単位出欠集計
      summaryDialog: false,
      summaryHeader: [
        { text: "登録日時", value: "monthJP"},
        { text: "未確認欠席", value: "unconfirmedKesseki"},
        { text: "未確認遅刻", value: "unconfirmedChikoku"},
        { text: "未確認早退", value: "unconfirmedSotai"},
        { text: "確認済欠席", value: "confirmedKesseki"},
        { text: "確認済遅刻", value: "confirmedChikoku"},
        { text: "確認済早退", value: "confirmedSotai"},
        { text: "確認済出停", value: "confirmedSyuttei"},
        { text: "確認済忌引", value: "confirmedKibiki"},
      ],
      summaryList: [],

      // 個別連絡作成用
      talkRoomDialog: false,
      talkRoomStudentId: "",
      talkRoomParentId: "",
      talkRoomStudentNm: "",
      talkRoomAssign: [],
      teacherList: [],

      // 出欠早退確定共通
      approvalStudentId: "",
      approvalStudentNm: "",
      selectMain: [],
      selectedMain: "", //初期値
      selectSub: [],
      selectSubFilter: [],
      approvalKbn: "", //初期値
      approvalText: "", //初期値

      // 出欠確定用
      attendanceApprovalDialog: false,
      selectedSyukketu: { label: "", syukketuKbn: 0 }, //初期値
      syukketu: [
        { label: "", syukketuKbn: 0 },
        { label: "欠席", syukketuKbn: 1 },
        { label: "遅刻", syukketuKbn: 5 },
        { label: "早退予定", syukketuKbn: 6 },
      ],
      biko: "",

      // 早退確定用
      leavingApprovalDialog: false,
      leavingReason: "",
      leavingTime: "",
      leavingMailFlg: false,

      // パスワードリセット用
      resetUserId: "",
      resetType: "0",
      resetDialog: false,      
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // グループ未選択は選択画面へ
    if(!this.groupId){
      this.onClicktoGroupSelect();
    }
    this.perPage = this.$store.state.parPageUserList;
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });

    // 教員リストの取得
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName: "commonGetTeachers", includeFlg: false}).then((ret) => {
      this.teacherList = ret.data.teacherList;
    })
  },
  methods: {
    // ヘッダー処理 start ************************************************************
    // ページ再読込
    async reload() {
      this.members = [];
      console.log("props",this.groupName,this.groupId);
      const data = {
        funcName: "loadUserList",
        groupId: this.groupId,
        yyyymmdd: this.yyyymmdd
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((users) => {
        var ret = [];
        users.data.forEach((user) => {
          var row = {
            studentId : user.loginId,
            parentId : user.parentId,
            userNm: user.userNm,
            userNmKana: user.userNmKana,
            kateiNm: user.kateiNm,
            buNm: user.buNm,
            kaNm: user.kaNm,
            gakunen: user.gakunen,
            gakkyu: user.gakkyu,
            syussekiNo: user.syussekiNo,
            consentFlg: user.consentFlg,
            mailAddressAuthFlg: user.mailAddressAuthFlg,
            mailAddress: user.mailAddress,
            mailAddress2: user.mailAddress2,
            passwordChangeFlg: user.passwordChangeFlg,
            parentConsentFlg: user.parentConsentFlg,
            parentMailAuthFlg: user.parentMailAuthFlg,
            parentMailAddress: user.parentMailAddress,
            parentMailAddress2: user.parentMailAddress2,
            parentPasswordChangeFlg: user.parentPasswordChangeFlg,
            syukketuInputFlg: user.syukketuInputFlg,
            sotaiInputFlg: user.sotaiInputFlg,
            syukketuApprovalFlg: user.syukketuApprovalFlg,
            sotaiApprovalFlg: user.sotaiApprovalFlg,
            groupId : this.groupId,
            pastLockFlg: user.pastLockFlg,
            existsFlg: user.existsFlg,
          };
          ret.push(row);
        });
        this.isListLoding = false;
        this.members = ret;
        console.log("groupId",this.members.groupId) ;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // 氏名カナ検索
    filter(value, search, item) {
      if(!item.userNmKana)return false;
      return item.userNmKana.toString().includes(search)
    },
    // データピッカー開閉
    calendar() {
      if(!this.$refs.picker.isOpen) {
          this.$refs.picker.$el.querySelector("input").focus();
          this.$refs.picker.showCalendar();
      } else {
          this.$refs.picker.close();
      }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
      this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 学期内ボタン制御
    pastLock(item, syukketu){
      var flg = false;
      if (syukketu) {
        // 出欠
        if (item.pastLockFlg) {
          flg = item.pastLockFlg;
        } else {
          flg = item.syukketuInputFlg ? false : true;
        }
      } else {
        // 早退
        if (item.pastLockFlg) {
          flg = item.pastLockFlg;
        } else {
          flg = item.sotaiInputFlg ? false : true;
        }
      }

      return flg;
    },
    // グループ選択に戻る
    onClicktoGroupSelect(){
      this.$router.push({
        name: "GroupSelect"
      });
    },
    // ヘッダー処理 end ************************************************************

    // 通常処理 start ************************************************************
    // 出欠集計(生徒単位月別)モーダル
    async viewAttendanceSummaryForStudent(item) {
      var param = {
        funcName: "viewAttendanceSummaryForStudent",
        studentId: item.studentId,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        this.summaryList = ret.data;
      });
      this.summaryDialog = true;
    },
    // 保護者個別連絡
    onClickTalkRoomDialog(item){
      this.talkRoomStudentNm = item.userNm;
      this.talkRoomStudentId = item.studentId;
      this.talkRoomParentId = item.parentId;
      this.talkRoomAssign = [];
      this.talkRoomDialog = true;
    },
    // 保護者個別連絡作成処理
    async openTalkRoom(){
      var param = {
        funcName: "openTalkRoom",
        studentId: this.talkRoomStudentId,
        parentId: this.talkRoomParentId,
        assignUserIds: this.talkRoomAssign,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message) {
          alert("作成に失敗しました");
        } else {
          this.$router.push({
            name: "TalkRoom",
            params: {
              roomId: ret.data.roomId,
            }
          });
        }
      });
    },

    // 通常処理 end ************************************************************

    // 出欠処理 start ************************************************************
    // 出欠訂正
    onClickSyukketu(item) {
      this.$router.push({
        name: "AttendanceEdit",
        params: {
          studentId: item.studentId,
          studentName: item.userNm,
          groupName: this.groupName,
          groupId: this.groupId,
          param_yyyymmdd: this.yyyymmdd,
        }
      });
    },
    // 早退登録
    onClickSoutai(item) {
      console.log(item.studentId);
      this.$router.push({
        name: "LeavingRegist",
        params: {
          studentId: item.studentId,
          studentName: item.userNm,
          groupName: this.groupName,
          groupId: this.groupId,
          parentMailAuthFlg: item.parentMailAuthFlg,
          param_yyyymmdd: this.yyyymmdd,
        }
      });
    },
    // 出欠早退初期処理
    async syukketuSotaiLoad(funcName, syukketuSotai) {
      // ローディング開始
      this.$root.loading = true;

      // 初期化
      this.selectMain = [];
      this.selectedMain = "";
      this.selectSub = [];
      this.selectSubFilter = [];
      this.approvalKbn = "";
      this.approvalText = "";

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: funcName,
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        if (syukketuSotai) {
          this.selectedSyukketu = res.data.syukketuKbn;
          this.biko = res.data.biko;
        } else {
          this.leavingReason = res.data.leavingReason;
          this.leavingTime = res.data.leavingTime;
        }
  
        this.selectMain = [{code: "", name: "", orderNo: "", yobi1: ""}].concat(res.data.selectMain);
        this.selectSub = res.data.selectSub;
        this.selectedMain = res.data.approvalKbn.substring(0, 2);
        if(res.data.approvalKbn != ""){
          this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
        }

        this.approvalKbn = res.data.approvalKbn;
        this.approvalText = res.data.approvalText;

      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });
    },
    // 出欠早退情報保存
    async syukketuSotaiSave(funcName, syukketuSotai) {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: funcName,
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId,
        approvalKbn: this.approvalKbn,
        approvalText: this.approvalText,
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        console.log(res.data);
        if (res.data) {
          alert("登録しました");

          // reloadせずにapprovalFlgの画面更新
          this.members.forEach((doc) => {
            if (doc.studentId === this.approvalStudentId) {
              if (syukketuSotai) {
                doc.syukketuApprovalFlg = res.data.approvalFlg;
              } else {
                doc.sotaiApprovalFlg = res.data.approvalFlg;
              }
            }
          });
        }
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });
    },
    // 出欠確定
    async onClickSyukketuApproval(item) {
      this.approvalStudentId = item.studentId;
      this.approvalStudentNm = item.userNm;
      this.attendanceApprovalDialog = true;

      // 出欠早退初期処理
      await this.syukketuSotaiLoad("loadAttendanceApproval", true);
    },
    // 出欠情報設定保存
    async syukketuApprovalSave() {
      await this.syukketuSotaiSave("saveAttendanceApproval", true);
      this.attendanceApprovalDialog = false;
    },
    // 早退確定
    async onClickSoutaiApproval(item) {
      console.log(item.studentId);
      this.approvalStudentId = item.studentId;
      this.approvalStudentNm = item.userNm;
      this.leavingApprovalDialog = true;

      // 出欠早退初期処理
      await this.syukketuSotaiLoad("loadLeavingApproval");
    },
    // 早退情報設定保存
    async sotaiApprovalSave() {
      await this.syukketuSotaiSave("saveLeavingApproval", false);
      this.leavingApprovalDialog = false;
    },
    async changeMain() {
      this.approvalKbn = "";
      this.approvalText = "";
      this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
    },
    async changeSub() {
      if(!this.approvalKbn.endsWith('99')){
        this.approvalText = "";
      }
    },
    // 出欠処理 start ************************************************************

    // パスワード初期化処理 start ************************************************************
    // パスワード初期化ダイアログ表示
    onClickResetDialog(item){
      this.resetUserId = item.studentId;
      this.consentFlg = item.consentFlg;
      this.mailAddressAuthFlg = item.mailAddressAuthFlg;
      this.parentConsentFlg = item.parentConsentFlg;
      this.parentMailAuthFlg = item.parentMailAuthFlg;
      this.resetDialog = true;
    },

    // パスワード初期化実行
    async doReset() {
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "initPassword",
        resetType: this.resetType,
        studentId: this.resetUserId,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        alert("パスワードを初期化しました");
        this.reload();
      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
    },
    // パスワード初期化処理 end ************************************************************


  },
};
</script>

<style>
.calender-text-box {
    display: none;
}
.vdp-datepicker__calendar {
    width: 100% !important;
}
</style>
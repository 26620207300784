<template>
  <v-container fluid>
    <v-alert
      v-if="!mailAddressAuthFlg"
      dense
      border="left"
      type="info"
      dismissible
    >
      右上の設定をクリックして、メールアドレスの登録をお願いいたします。
    </v-alert>

    <v-row dense>
      <v-col cols="12">
        <v-tabs fixed-tabs v-model="tab" active-class="green lighten-5">
          <v-tab href="#contact">
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 0 && unreadContactCount <= 99">{{ unreadContactCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadContactCount > 99">99+</v-btn>
            校内連絡
          </v-tab>
          <v-tab href="#survey">
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 0 && unreadSurveysCount <= 99">{{ unreadSurveysCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadSurveysCount > 99">99+</v-btn>
            アンケート
          </v-tab>
          <v-tab href="#talkRoom">
            <v-btn color="red" small icon outlined v-if="unreadTalkRoomCount > 0 && unreadTalkRoomCount <= 99">{{ unreadTalkRoomCount }}</v-btn>
            <v-btn color="red" small icon outlined v-if="unreadTalkRoomCount > 99">99+</v-btn>
            個別連絡
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <!-- =======================連絡======================= -->
          <v-tab-item value="contact">
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog1 = true" small>
                  <v-icon right dark v-if="isFilterFlg1">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg1">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue1"
                  label="タイトル検索"
                  placeholder="タイトル検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="groupFilterValue1"
                  label="グループ指定"
                  placeholder="グループ指定"
                  :items="groupNames1"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="postUsersFilterValue1"
                  label="投稿者指定"
                  placeholder="投稿者指定"
                  :items="postUsers1"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewUnreadOnly1"
                  @click="changeFilter1(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewFavoriteOnly1"
                  @click="changeFilter1(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="contacts"
              :items-per-page.sync="parPageContact"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              @click:row="onClickContactDetails"
              :item-class="rowStyleContact"
              :loading="isContactsLoding" loading-text="読込中"
              mobile-breakpoint="0"
              ref="contact"
            >
              <!-- 最終更新日時 -->
              <template v-slot:top>
                <v-row dense>
                  <v-col cols="12" class="d-flex text-caption justify-end">
                    <div>最終更新時間：{{ lastReloadTime }}(15分毎自動更新)</div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </template>

              <!-- タイトルとモバイル用まとめセル -->
              <template #[`item.title`]="{ item }">
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    {{ item.title }}
                  </v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(連絡タイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況) -->
                    <v-row no-gutters>
                      <v-col cols="9" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="3" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(対象ユーザー区分＋コメント＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <v-icon v-if="item.teacherFlg">$teacher</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                        <v-icon v-if="item.unreadCommentCount > 0" x-small color="red">mdi-new-box</v-icon>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 投稿日と投稿者 -->
              <template #[`item.postUser`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <v-icon x-large v-if="item.teacherFlg">$teacher</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <!-- コメントフラグ -->
              <template #[`item.commentFlg`]="{ item }">
                <v-icon class="ml-2" v-if="item.commentFlg">mdi-comment-text-outline</v-icon>
                <v-icon v-if="item.unreadCommentCount > 0" large color="red">mdi-new-box</v-icon>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <!-- グループ名と代表者 -->
              <template #[`item.groupName`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.ownerUser }}</v-col></v-row>
              </template>
              <!-- 既読/未読 -->
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.unreadState }}
              </template>
            </v-data-table>
          </v-tab-item>
          <!-- =======================アンケート======================= -->
          <v-tab-item value="survey">
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog2 = true" small>
                  <v-icon right dark v-if="isFilterFlg2">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg2">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <!-- 検索用のテキスト入力 -->
                <v-text-field
                  v-model="titleFilterValue2"
                  label="ﾀｲﾄﾙ検索"
                  placeholder="ﾀｲﾄﾙ検索"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="groupFilterValue2"
                  label="グループ指定"
                  placeholder="グループ指定"
                  :items="groupNames2"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="postUsersFilterValue2"
                  label="投稿者指定"
                  placeholder="投稿者指定"
                  :items="postUsers2"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewUnreadOnly2"
                  @click="changeFilter2(true)"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                <v-switch 
                  v-model="viewFavoriteOnly2"
                  @click="changeFilter2(false)"
                  dense
                  class="mt-1"
                  label="お気に入りのみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="surveyheaders"
              :items="surveylist"
              :items-per-page.sync="parPageSurvey"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              @click:row="onClickSurveyDetails"
              :item-class="rowStyleSurvey"
              :loading="isSurveysLoding" loading-text="読込中"
              mobile-breakpoint="0"
              ref="survey"
            >
              <!-- 最終更新日時 -->
              <template v-slot:top>
                <v-row dense>
                  <v-col cols="12" class="d-flex text-caption justify-end">
                    <div>最終更新時間：{{ lastReloadTime }}(15分毎自動更新)</div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </template>

              <!-- タイトルと締切日とモバイル用まとめセル -->
              <template #[`item.title`]="{ item }">
                <v-row dense class="hidden-xs-only"><v-col cols="12" justify="center">{{ item.title }}</v-col></v-row>
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center" class="red--text" v-if="item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                  <v-col cols="12" justify="center" v-if="!item.limitOverFlg">
                    {{ item.limitDate }}
                  </v-col>
                </v-row>

                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(グループ名＋投稿者) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        {{ item.groupName }}
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.postUser }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(アンケートタイトル) -->
                    <v-row no-gutters>
                      <v-col cols="12" class="text-subtitle-1">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <!-- ３行目(公開開始日＋既読状況＋入力状況) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        公開:{{ item.openDate }}
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        {{ item.unreadState }}：
                        {{ item.inputState }}
                      </v-col>
                    </v-row>
                    <!-- ４行目(回答期限＋対象ユーザー区分＋お気に入り) -->
                    <v-row no-gutters>
                      <v-col cols="8" class="text-caption">
                        期限:<span v-if="item.limitOverFlg" class="red--text">{{ item.limitDate }}</span>
                        <span v-if="!item.limitOverFlg">{{ item.limitDate }}</span>
                      </v-col>
                      <v-col cols="4" class="d-flex justify-end text-caption">
                        <v-icon v-if="item.studentFlg">$student</v-icon>
                        <v-icon v-if="item.parentFlg">$parent</v-icon>
                        <v-icon v-if="item.teacherFlg">$teacher</v-icon>
                        <div v-if="item.targetSelectFlg">【限定】</div>
                        <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                        <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
              <!-- 投稿日と投稿者 -->
              <template #[`item.postUser`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.openDate }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
              </template>
              <template #[`item.target`]="{ item }">
                <v-icon x-large v-if="item.studentFlg">$student</v-icon>
                <v-icon x-large v-if="item.parentFlg">$parent</v-icon>
                <v-icon x-large v-if="item.teacherFlg">$teacher</v-icon>
                <div v-if="item.targetSelectFlg">【限定】</div>
              </template>
              <!-- グループ名と代表者 -->
              <template #[`item.groupName`]="{ item }">
                <v-row dense><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
                <v-row dense><v-col cols="12" justify="center">{{ item.ownerUser }}</v-col></v-row>
              </template>
              <!-- お気に入り -->
              <template #[`item.favoriteFlg`]="{ item }">
                <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
                <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
              </template>
              <template #[`item.unreadFlg`]="{ item }">
                {{ item.inputState }}
              </template>
            </v-data-table>
          </v-tab-item>
          <!-- =======================個別連絡======================= -->
          <v-tab-item value="talkRoom">
            <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="green" @click="filterDialog3 = true" small>
                  <v-icon right dark v-if="isFilterFlg3">mdi-filter</v-icon>
                  <v-icon right dark v-if="!isFilterFlg3">mdi-filter-outline</v-icon>
                  フィルタ
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <!-- 検索用のテキスト入力 -->
                <v-autocomplete
                  v-model="studentFilterValue"
                  label="生徒指定"
                  placeholder="生徒指定"
                  :items="studentList"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  background-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="parentUpdateLimited"
                  dense
                  class="mt-1"
                  label="最終更新が保護者のみ"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                <v-switch 
                  v-model="viewUnreadOnly3"
                  dense
                  class="mt-1"
                  label="未読のみ"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-data-table
              :headers="talkRoomHeaders"
              :items="talkRoomList"
              :items-per-page.sync="parPageTalkRoom"
              :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
              dense
              disable-sort
              @click:row="onClickTalkRoom"
              :loading="isTalkRoomLoding" loading-text="読込中"
              mobile-breakpoint="0"
              ref="talkRoom"
            >
              <!-- 最終更新日時 -->
              <template v-slot:top>
                <v-row dense>
                  <v-col cols="12" class="d-flex text-caption justify-end">
                    <div>最終更新時間：{{ lastReloadTime }}(15分毎自動更新)</div>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </template>

              <!-- 更新日と更新者とモバイル用まとめセル -->
              <template #[`item.lastMessage`]="{ item }">
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center">
                    最終更新:{{ item.lastMessageDate }}
                  </v-col>
                </v-row>
                <v-row dense class="hidden-xs-only">
                  <v-col cols="12" justify="center" v-if="!item.lastMessageParentFlg">{{ item.lastMessageUserNm }}</v-col>
                  <v-col cols="12" justify="center" v-if="item.lastMessageParentFlg" class="red--text">{{ item.lastMessageUserNm }}</v-col>
                </v-row>
                <!-- モバイル用まとめ -->
                <v-row dense class="d-sm-none">
                  <v-col cols="12" justify="center">
                    <!-- １行目(学内学外区分＋最終メッセージ日付) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-caption">
                        <div v-if="!item.inhouseFlg" class="font-weight-bold red--text">学外</div>
                        <div v-if="item.inhouseFlg">学内</div>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end text-caption">
                        {{ item.lastMessageDate }}
                      </v-col>
                    </v-row>
                    <!-- ２行目(連絡宛先＋既読状況) -->
                    <v-row no-gutters>
                      <v-col cols="6" class="text-subtitle-2">
                        <span style="white-space : nowrap;">
                          {{ item.studentNm }}
                          <span v-if="item.targetParentFlg">の保護者様</span>
                        </span>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end">
                        <v-btn color="red" x-small icon outlined v-if="item.unreadFlg">{{ item.unread }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>

              <!-- 生徒名 -->
              <template #[`item.studentNm`]="{ item }">
                <v-row dense v-if="!item.inhouseFlg">
                  <v-col cols="12" justify="center">
                    <span style="white-space : nowrap;">{{ item.studentNm }}<span v-if="item.targetParentFlg">の保護者様</span></span>
                  </v-col>
                </v-row>
              </template>

              <!-- 学内/学外 -->
              <template #[`item.lastMessageParentFlg`]="{ item }">
                <div v-if="!item.inhouseFlg" class="font-weight-bold red--text">学外</div>
                <div v-if="item.inhouseFlg">学内</div>
              </template>

              <!-- 未読件数 -->
              <template #[`item.unreadFlg`]="{ item }">
                <v-btn color="red" small icon outlined v-if="item.unreadFlg">{{ item.unread }}</v-btn>
              </template>

            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- 左下読み込みボタン -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom left color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 右下メニュー -->
    <v-speed-dial fixed fab bottom right v-model="funcButton" style="bottom: 50px">
      <template v-slot:activator>
        <v-tooltip left v-if="funcButton">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-model="funcButton" color="secondary" fab>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>閉じる</span>
        </v-tooltip>
        <v-tooltip left v-if="!funcButton">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" v-model="funcButton" color="secondary" fab>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>連絡を作成する</span>
        </v-tooltip>
      </template>
      
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" fab dark small v-bind="attrs" v-on="on" @click="toContactRegist">
            <v-icon>mdi-email-plus</v-icon>
          </v-btn>
        </template>
        <span>グループ宛に連絡を作成する</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" fab dark small v-bind="attrs" v-on="on" @click="toSurveyRegist">
            <v-icon>mdi-table-plus</v-icon>
          </v-btn>
        </template>
        <span>グループ宛にアンケートを作成する</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="blue" fab dark small v-bind="attrs" v-on="on" @click="onClickTalkRoomDialog">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <span>教員宛に個別連絡を作成する</span>
      </v-tooltip>

    </v-speed-dial>

    <!-- 個別連絡作成モーダル -->
    <v-dialog
      v-model="talkRoomDialog"
      persistent
      max-width="800"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="talkRoomDialog = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" justify="center">
                <p>教員間の個別連絡を作成</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="accent" class="black--text" @click="openTalkRoomForTeacher()" :disabled="invalid" width="90%">
                  作成
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="やりとりに参加する教員(自身以外)" rules="required" :skipIfEmpty="false" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="talkRoomAssign"
                    prepend-icon="$cet_required"
                    label="やりとりに参加する教員(自身以外)"
                    placeholder="やりとりに参加する教員(自身以外)"
                    :items="teacherList"
                    outlined
                    dense
                    multiple
                    deletable-chips
                    small-chips
                    :error-messages="errors[0]"
                    hide-details="auto"
                    background-color="white"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- 連絡フィルタモーダル -->
    <v-dialog
      v-model="filterDialog1"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter1" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter1" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue1"
                label="ﾀｲﾄﾙ検索"
                placeholder="ﾀｲﾄﾙ検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="groupFilterValue1"
                label="グループ指定"
                placeholder="グループ指定"
                :items="groupNames1"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="postUsersFilterValue1"
                label="投稿者指定"
                placeholder="投稿者指定"
                :items="postUsers1"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly1"
                @click="changeFilter1(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly1"
                @click="changeFilter1(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- アンケートフィルタモーダル -->
    <v-dialog
      v-model="filterDialog2"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter2" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter2" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue2"
                label="ﾀｲﾄﾙ検索"
                placeholder="ﾀｲﾄﾙ検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="groupFilterValue2"
                label="グループ指定"
                placeholder="グループ指定"
                :items="groupNames2"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="postUsersFilterValue2"
                label="投稿者指定"
                placeholder="投稿者指定"
                :items="postUsers2"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly2"
                @click="changeFilter2(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly2"
                @click="changeFilter2(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 個別連絡フィルタモーダル -->
    <v-dialog
      v-model="filterDialog3"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter3" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter3" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="studentFilterValue"
                label="生徒指定"
                placeholder="生徒指定"
                :items="studentList"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="parentUpdateLimited"
                dense
                class="mt-1"
                label="最終更新が保護者のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly3"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
  data() {
    return {
      tab: 'contact',
      // 連絡
      filterDialog1: false,
      isFilterFlg1: false,
      titleFilterValue1: "",
      postUsersFilterValue1: "",
      groupFilterValue1: "",
      viewUnreadOnly1: false,
      viewFavoriteOnly1: false,
      postUsers1: [],
      groupNames1: [],
      // アンケート
      filterDialog2: false,
      isFilterFlg2: false,
      titleFilterValue2: "",
      postUsersFilterValue2: "",
      groupFilterValue2: "",
      viewUnreadOnly2: false,
      viewFavoriteOnly2: false,
      postUsers2 : [],
      groupNames2 : [],
      // 個別連絡用
      filterDialog3: false,
      isFilterFlg3: false,
      studentFilterValue: "",
      studentList : [],
      parentUpdateLimited: false,
      viewUnreadOnly3: false,

      headers: [
        { text: "タイトル", value: "title", class: "px-0 hidden-xs-only", filter: this.titleFilter1 },
        { text: "グループ名/代表者", value: "groupName", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.groupNameFilter1},
        { text: "対象", value: "target", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", },
        { text: "更新日時/更新者", value: "postUser", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.postUserFilter1 },
        { text: "コメント", value: "commentFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", },
        { text: "お気に入り", value: "favoriteFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter1 },
        { text: "既読/未読", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter1 },
      ],
      contacts: [],
      surveyheaders : [
        { text: "タイトル/回答期限", value: "title", class: "px-0 hidden-xs-only", filter: this.titleFilter2 },  
        { text: "グループ名/代表者", value: "groupName", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.groupNameFilter2},
        { text: "対象", value: "target", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "更新日時/更新者", value: "postUser", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.postUserFilter2 },
        { text: "お気に入り", value: "favoriteFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter2 },
        { text: "既読/未読", value: "unreadState", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", },
        { text: "入力状況", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter2 },
      ],
      surveylist : [],
      talkRoomHeaders : [
        { text: "更新日時/更新者", value: "lastMessage", class: "px-0 hidden-xs-only", width: "300" },  
        { text: "学内/学外", value: "lastMessageParentFlg" , class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "100", filter: this.parentUpdateFilter }, 
        { text: "宛先", value: "studentNm" , class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "200", filter: this.studentFilter }, 
        { text: "教員", value: "teacherNms" , class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "200" },
        { text: "未読件数", value: "unreadFlg", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only", width: "100", filter: this.unreadFilter3 }, 
      ],
      talkRoomList : [],
      isContactsLoding: false,
      isSurveysLoding: false,
      isTalkRoomLoding: false,
      mailAddressAuthFlg: true,
      parPageContact: 30,
      parPageSurvey: 30,
      parPageTalkRoom: 30,
      unreadContactCount: 0,
      unreadSurveysCount: 0,
      unreadTalkRoomCount: 0,

      // 個別連絡作成用
      funcButton: false,
      talkRoomDialog: false,
      talkRoomAssign: [],
      teacherList: [],

      intervalid1:"",
      lastReloadTime:"",
    };
  },
  watch: {
    tab: function(newVal) {this.$store.dispatch('setViewConditionTabSelect', newVal)},
    parPageContact: function(newVal) {this.$store.dispatch('setParPageTeacherTopPageContact', newVal)},
    parPageSurvey: function(newVal) {this.$store.dispatch('setParPageTeacherTopPageSurvey', newVal)},
    parPageTalkRoom: function(newVal) {this.$store.dispatch('setParPageTeacherTopPageTalkRoom', newVal)},

    groupFilterValue1: function(newVal) {this.$store.dispatch('setSearchConditionGroupName', newVal)},
    postUsersFilterValue1: function(newVal) {this.$store.dispatch('setSearchConditionCreateUser', newVal)},
    viewUnreadOnly1: function(newVal) {this.$store.dispatch('setSearchConditionUnread', newVal)},
    viewFavoriteOnly1: function(newVal) {this.$store.dispatch('setSearchConditionFavorite', newVal)},
  },
  // 初期表示時データ読み込み
  async created() {
    console.log("created");
    this.tab = this.$store.state.viewConditionTabSelect;
    this.parPageContact = this.$store.state.parPageTeacherTopPageContact;
    this.parPageSurvey = this.$store.state.parPageTeacherTopPageSurvey;
    this.parPageTalkRoom = this.$store.state.parPageTeacherTopPageTalkRoom;

    this.groupFilterValue1 = this.$store.state.searchConditionGroupName;
    this.postUsersFilterValue1 = this.$store.state.searchConditionCreateUser;
    this.viewUnreadOnly1 = this.$store.state.searchConditionUnread;
    this.viewFavoriteOnly1 = this.$store.state.searchConditionFavorite;

    // 教員リストの取得
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName: "commonGetTeachers", includeFlg: false}).then((ret) => {
      this.teacherList = ret.data.teacherList;
    })

    await this.reload();

    // 定期更新の設定
    this.intervalid1 = setInterval(() => {
      this.reload();
    }, 900000);
  },
  beforeDestroy () {
    // 定期更新の設定破棄
    clearInterval(this.intervalid1)
  },
  methods: {

    // 連絡詳細
    onClickContactDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.contact.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var contactListBuff = Enumerable.from(items).select(x => { return x.messageId }).toArray();
      var contactListIndex = contactListBuff.indexOf(item.messageId);

      this.$router.push({
        name: "ContactDetails",
        params: {
          messageId: item.messageId,
          contactListBuff: contactListBuff,
          contactListIndex: contactListIndex,
        } 
      });
    },
    // アンケート回答
    onClickSurveyDetails(item) {

      // フィルタされた配列の取得
      let items = this.$refs.survey.$children[0].filteredItems;
      // 調査IDのみ抽出
      var surveyListBuff = Enumerable.from(items).select(x => { return x.responseId }).toArray();
      var surveyListIndex = surveyListBuff.indexOf(item.responseId);

      this.$router.push({
        name: "SurveyDetails",
        params: {
          responseId : item.responseId,
          surveyListBuff: surveyListBuff,
          surveyListIndex: surveyListIndex,
        } 
      });
    },
    // 個別連絡
    onClickTalkRoom(item) {
      this.$router.push({
        name: "TalkRoom",
        params: {
          roomId : item.roomId,
        } 
      });
    },
    // 教員宛個別連絡
    onClickTalkRoomDialog(){
      this.talkRoomAssign = [];
      this.talkRoomDialog = true;
    },
    // 教員宛個別連絡作成処理
    async openTalkRoomForTeacher(){
      var param = {
        funcName: "openTalkRoomForTeacher",
        assignUserIds: this.talkRoomAssign,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message) {
          alert("作成に失敗しました");
        } else {
          this.$router.push({
            name: "TalkRoom",
            params: {
              roomId: ret.data.roomId,
            }
          });
        }
      });
    },
    // ページ再読込
    async reload() {
      console.log("reload");
      var unreadContactCount = 0;
      var unreadSurveysCount = 0;
      var unreadTalkRoomCount = 0;
      
      
      // 学校からの連絡取得
      this.contacts = [];
      this.surveylist = [];
      this.talkRoomList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isContactsLoding = true;
      this.isSurveysLoding = true;
      this.isTalkRoomLoding = true;
      var topPage = schoolAidHomeLogined({funcName:"loadTopPage"}).then((contacts) => {
        var ret = [];
        var temp_postUsers = new Set();
        var temp_groupNames = new Set();
        contacts.data.list.forEach((contact) => {
          temp_postUsers.add(contact.postUser);
          temp_groupNames.add(contact.groupName);
          var row = {
            contactId: contact.contactId,
            openDate: contact.openDate,
            postUser: contact.postUser,
            groupName: contact.groupName,
            ownerUser: contact.ownerUser,
            studentFlg: contact.studentFlg,
            parentFlg: contact.parentFlg,
            teacherFlg: contact.teacherFlg,
            title: contact.title,
            messageId: contact.messageId,
            commentFlg: contact.commentFlg,
            unreadFlg: (contact.unreadFlg)?contact.unreadFlg:(contact.unreadCommentCount>0),
            unreadCommentCount: contact.unreadCommentCount,
            favoriteFlg: contact.favoriteFlg,
            targetSelectFlg: contact.targetSelectFlg,
          };
          if(row.unreadFlg){
            row.unreadState = "未読";
            unreadContactCount++;
          } else {
            row.unreadState = "既読";
          }
          ret.push(row);
        });
        this.unreadContactCount = unreadContactCount;
        this.contacts = ret;
        this.mailAddressAuthFlg = contacts.data.mailAddressAuthFlg;
        this.isContactsLoding = false;
        this.postUsers1 = Array.from(temp_postUsers);
        this.groupNames1 = Array.from(temp_groupNames);
      });
      var topPageSurvey = schoolAidHomeLogined({funcName:"loadTopPageSurvey"}).then((snapshot) => {
        var ret = [];
        var temp_postUsers = new Set();
        var temp_groupNames = new Set();
        snapshot.data.forEach((survey) => {
          temp_postUsers.add(survey.postUser);
          temp_groupNames.add(survey.groupName);
          var row = {
            surveyId: survey.surveyId,
            openDate: survey.openDate,
            limitDate: survey.limitDate,
            limitOverFlg: survey.limitOverFlg,
            postUser: survey.postUser,
            groupName: survey.groupName,
            ownerUser: survey.ownerUser,
            studentFlg: survey.studentFlg,
            parentFlg: survey.parentFlg,
            teacherFlg: survey.teacherFlg,
            title: survey.title,
            responseId: survey.responseId,
            unreadFlg: survey.unreadFlg,
            inputFlg: survey.inputFlg,
            favoriteFlg: survey.favoriteFlg,
            targetSelectFlg: survey.targetSelectFlg,
          };
          if(survey.unreadFlg){
            row.unreadState = "未読";
            unreadSurveysCount++;
          } else {
            row.unreadState = "既読";
          }
          if(survey.inputFlg){
            row.inputState = "入力済";
          } else {
            row.inputState = "未入力";
          }
          ret.push(row);
        });
        this.unreadSurveysCount = unreadSurveysCount;
        this.surveylist = ret;
        this.isSurveysLoding = false;
        this.postUsers2 = Array.from(temp_postUsers);
        this.groupNames2 = Array.from(temp_groupNames);
      });
      var topPageTalkRoom = schoolAidHomeLogined({funcName:"loadTopPageTalkRoom"}).then((snapshot) => {
        var ret = [];
        var temp_studentNms = new Set();
        snapshot.data.forEach((recode) => {
          // 生徒名の格納
          if(recode.studentNm)temp_studentNms.add(recode.studentNm);
          var row = {
            roomId: recode.roomId,
            lastMessageDate: recode.lastMessageDate,
            lastMessageUserNm: recode.lastMessageUserNm,
            lastMessageParentFlg: recode.lastMessageParentFlg,
            studentNm: recode.studentNm,
            targetParentFlg: recode.targetParentFlg,
            inhouseFlg: recode.inhouseFlg,
            teacherNms: recode.teacherNms,
            teacherNmList: recode.teacherNmList,
            lockFlg: recode.lockFlg,
            total: recode.total,
            read: recode.read,
            unread: (recode.total - recode.read),
            unreadFlg: (recode.total != recode.read),
          };
          // 未読件数の集計
          unreadTalkRoomCount = unreadTalkRoomCount + (recode.total - recode.read);
          ret.push(row);
        });
        this.unreadTalkRoomCount = unreadTalkRoomCount;
        this.talkRoomList = ret;
        this.isTalkRoomLoding = false;
        this.studentList = Array.from(temp_studentNms);
      });
      await Promise.all( [ topPage, topPageSurvey, topPageTalkRoom ] );
      this.lastReloadTime = format(new Date(), "HH時mm分ss秒", { locale: ja });
    },
    rowStyleContact: function (item) {
      return item.unreadFlg == true ? 'not-yet-style' : 'do-style'
    },
    rowStyleSurvey: function (item) {
      return item.inputFlg == true ? 'do-style' : 'not-yet-style'
    },
    // 連絡登録画面へ
    toContactRegist() {
      this.$router.push({
        name: "ContactRegist",
      });
    },
    // アンケート登録画面へ
    toSurveyRegist() {
      this.$router.push({
        name: "SurveyRegist",
      });
    },
    // 検索(連絡用)=================================================================================
    // タイトルフィルタ
    titleFilter1(value) {
      if (!this.titleFilterValue1) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue1);
    },
    // 投稿者名フィルタ
    postUserFilter1(value) {
      if (!this.postUsersFilterValue1) {
        return true;
      }
      return value.toString().includes(this.postUsersFilterValue1);
    },
    // グループ名フィルタ
    groupNameFilter1(value) {
      if (!this.groupFilterValue1) {
        return true;
      }
      return value.toString() == this.groupFilterValue1;
    },
    // 未読フィルタ
    unreadFilter1(value) {
      if (!this.viewUnreadOnly1) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter1(value) {
      if (!this.viewFavoriteOnly1) {
        return true;
      }
      return value;
    },
    changeFilter1(flg){
      if(this.viewUnreadOnly1 && this.viewFavoriteOnly1){
        if(flg){
          this.viewFavoriteOnly1 = false;
        } else {
          this.viewUnreadOnly1 = false;
        }
      }
    },
    // 検索(アンケート用)=================================================================================
    // タイトルフィルタ
    titleFilter2(value) {
      if (!this.titleFilterValue2) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue2);
    },
    // 投稿者名フィルタ
    postUserFilter2(value) {
      if (!this.postUsersFilterValue2) {
        return true;
      }
      return value.toString().includes(this.postUsersFilterValue2);
    },
    // グループ名フィルタ
    groupNameFilter2(value) {
      if (!this.groupFilterValue2) {
        return true;
      }
      return value.toString() == this.groupFilterValue2;
    },
    // 未読フィルタ
    unreadFilter2(value) {
      if (!this.viewUnreadOnly2) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter2(value) {
      if (!this.viewFavoriteOnly2) {
        return true;
      }
      return value;
    },
    changeFilter2(flg){
      if(this.viewUnreadOnly2 && this.viewFavoriteOnly2){
        if(flg){
          this.viewFavoriteOnly2 = false;
        } else {
          this.viewUnreadOnly2 = false;
        }
      }
    },

    // 検索(個別連絡用)=================================================================================
    // 生徒名フィルタ
    studentFilter(value) {
      if (!this.studentFilterValue) {
        return true;
      }
      return value.toString().includes(this.studentFilterValue);
    },
    // 保護者更新限定フィルタ
    parentUpdateFilter(value) {
      if (!this.parentUpdateLimited) {
        return true;
      }
      return value;
    },
    // 未読フィルタ
    unreadFilter3(value) {
      if (!this.viewUnreadOnly3) {
        return true;
      }
      return value;
    },

    // 検索モーダル用 =================================================================================
    doFilter1() {
      this.isFilterFlg1 = true;
      this.filterDialog1 = false;
    },
    clearFilter1(){
      this.titleFilterValue1 = "";
      this.postUsersFilterValue1 = "";
      this.groupFilterValue1 = "";
      this.viewUnreadOnly1 = false;
      this.viewFavoriteOnly1 = false;

      this.isFilterFlg1 = false;
      this.filterDialog1 = false;
    },
    doFilter2() {
      this.isFilterFlg2 = true;
      this.filterDialog2 = false;
    },
    clearFilter2(){
      this.titleFilterValue2 = "";
      this.postUsersFilterValue2 = "";
      this.groupFilterValue2 = "";
      this.viewUnreadOnly2 = false;
      this.viewFavoriteOnly2 = false;

      this.isFilterFlg2 = false;
      this.filterDialog2 = false;
    },
    doFilter3() {
      this.isFilterFlg3 = true;
      this.filterDialog3 = false;
    },
    clearFilter3(){
      this.studentFilterValue = "";
      this.parentUpdateLimited = false;
      this.viewUnreadOnly3 = false;

      this.isFilterFlg3 = false;
      this.filterDialog3 = false;
    },
  },  
};
</script>

<style scoped>
.do-style {
  background-color: rgb(182, 182, 182)
}
.do-style > td {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
.not-yet-style > td {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

</style>